// bp-header-top-up = screen and (min-width: 850px)
// bp-header-top-down = screen and (max-width: 849px)
// bp-contacts-up = 'screen and (min-width: 500px)'
// bp-contacts-down = 'screen and (max-width: 499px)'


// $_has-padding
// 	padding 1rem

// .confirm
// .purchase
// .total-price
// .section
// .fields-block
// .password-change
// .logout
// 	@extend $_has-padding

/*.l-imagelink
	width 100%
	position relative
	&__img
		width 100%*/
		

// УНИВЕРСАЛЬНЫЕ СТРУКТУРНЫЕ

// FONT-КОНТРОЛЛЕРЫ

html
	//в rem у нас задаются марджины и паддинги, поэтому тут фактически регулируем их. Имеет смысл задавать в vw или px, можно даже в принципе в % наверно
	@media bp-content-a
		font-size 1vw
	@media bp-content-b
		font-size 1vw

body
	font-size 16px
	// предовтращаем каскадинг rem- font-siz'а из html

// не получится паддинг задавать в rem, потому что не все элементы на странице должны иметь плпвающий шрифт, а если мы пропишем, то html повлияет на всё
// но прописываем в  body, чтобы заданные вариации в html не каскадировали. Т.о если нам нужно, чтобы что-то изменялось в соответствии с тем что прописано в html - используем rem

// снимаем с макета размеры в пикселях и переводим их в em или % (смотря что требуется), чтобы получить pixel perfect

.banner.font-controller
	// font-size 1vw
	@media bp-content-a
		font-size 1vw
	@media bp-content-b
		font-size 16px
	


// RATIO-BOX

.l-board
	// Описание: элемент, являющийся "доской" с заданными пропорциями для размещения абсолютно позиционированных элементов-"магнитов"
	// Требует применения к себе ratio-box (с кастомной пропорцией) Ниже брекпойнта задаётся переход к флекс-лэйауту; ratio-box надо отменить, иначе элемент схлопнется
	// если используем для bg-слайдера, например, то в случае, если слайдер постоянно сохраняет пропорцию под ratio-bоx'ом при любом вьюпорте, .l-board лучше не ставить
	align-self stretch // ширина должна быть определена
	pos(relative)
	@media bp-content-b
		// padding 1rem
		grid(stack, a: center)
		// глюк Firefox: если использовать flex одновременно с ratio-box, элемент схлопывается. Т.е. надо так построить квери, чтобы они не пересекались
		// http://stackoverflow.com/questions/23717953/padding-bottom-top-in-flexbox-layout

/*.l-magnet
	cent()
	z-index 2
	@media bp-content-b
		width 100%
		padding 1rem*/	

// SHELL /  CORE
// философская проблема: можно плясать либо от ширины блоков (тогда контейнер может адаптироваться под ширину контента)
// либо от ширины контейнера (начиная от вьюпорта) тогда блоки будут рассчитываться в % от ширины контейнера

// центрирование контейнера по типу .l-core обычно не задаёт его ширину, т.е предполагает первый подход.
// но тогда блокам, который находятся внутри блоков с не установленной шириной, не получится задать ширину в %.
// Если это картинки, имеющие собственнй размер, то хорошо, но если это пустые блоки, которые надо растянуть (field, например)
// всё равно придётся задавать какую-то width в px (или em, rem...). Т.е. width (абс. ед)
// должна бть также max-width, чтобы содержимое не вываливалось за пределы шелла и экрана, но max-width в данном случае можно поставить только на .l-core,
// т.к. ниже ширина элементов не определена. 
// в общем, надо ставить width в абс. ед на элемент внутри .l-core или на сам .l-core, что имеет смысл, т.к. для него уже задана max-width всё равно. К тому же для .l-core можно задать width и в %!

// поэтому установки ширины надо делать непоследственно на блоке, где стоит .l-core

//.l-main и .l-content являются фактически .l-shell
// .l-section является .l-core


.l-mixin-shell
	width 100%
	grid(stack, a: center)
	
	& > *
		max-width 100%
	// можно использовать в виде блок-миксина без .l-core, т.к. все дочерние элементы являются .l-core по умолчанию
	// для дочернего элемента желательно задать width. 
 	// если это текстовый description, он не требует установленного width, потому может быть просто ограничен с помощью max-width в $_text-module.
 	// однако если max-width будет задан в шелле, он перепишет max-width в $_text-module, т.е. там надо ставить !important
 	// если это блок каталога, для него нужно задать установленную ширину (100%, или какую-то другую, но это уже надо прописать в конкретном классе)
 	// + max-width будет работать для ограничения максимальной ширины



.l-shell
	// используется вместе с .l-core
	// должен иметь установленную ширину (обычно по ширине экрана)
	width 100%
	// спейсинг можно задавать для конкретных модулей в каждом случае отдельно с помощью space-items()
	
	// центровка может также осуществляться l-shell'ом. Но, в одном шелле могут быть элементы, не требующие центровки (не являющиеся .l-core)
	// тогда для них придётся прописыть оверрайд обратно к дефолту, что тупо и не гибко. Поэтому центровку переносим в класс .l-core
	// хотя, часто бывают случаи, еогда центрировать нужно много мелких кастомных элементов типа всяких текстовых, и вот для них прописывать align- center напряжненько
	grid(stack, a: center)
	
	& > *
		max-width 100%
		// align-self center
		

// $_all-pages, .l-main, l-content работают как .l-shell, т.к. для них не надо задавать специально этот класс

.l-core
	// .l-core использовать для объединения элементов, которые должны сохранять позицию относительно друг друга (быть вертикально на одной линии, например)
	// должен быть центрирован внутри .l-shell
	// auto-margin()
	max-width 100% // не шире чем shell. Т.к. l-core не имеет установленной ширины, оганичиваем по ширине shell
	//.l-core должен иметь непосредственным родителем элемент с установленной шириной, иначе max-width 100% не сработает.
	//этим элементом может быть .l-shell, .l-main. .l-content
	align-self center
	grid(stack, g: 2rem bottom)
	
	// управляем шириной в коде модуля, для которого выставлен .l-core, по типу:
	// +above(900px)
	// 	width 850px
	// +below(900px)
	// 	width 400px
	// можно в %, если для родителя определена ширина
	
.l-core--form
	@extends .l-core
	// width 100%
	+above(900px)
		width 850px
	+below(900px)
		width 400px


.l-svg-cont
	width 100%

.l-section
	// для лэйаут-класса мы прописываем только те правила, которые должны применяться к подавляющему большинству таких элементов
	// обычно применяемые стили для стандартного тэга section, который состоит из заголовка и элемента __main, которые надо расположить стэком и разделить гаттером
	grid(section, g: 2rem bottom)
	
	// по умолчанию контент внутри секции центрируется флексом
	// т.к секция сама центрируется (является core) нет смысла центрировать контент внутри (обычно это заголовок и блок .l-section__main)
	// а вот внутри l-section__main уже можно центрировать, но указывается индивидуально для секции
	// т.е. нужно определиться: либо мы центрируем саму секцию, либо контент внутри неё, одно из двух.


	// для каталогов такое не катит, т.к. они должны быть в 100% ширины, т.е. необходимо прописать для их __main 100% width или для секции каталога оверрайд align-items stretch	
	// плюс, сама секция должна в этом случае занимать 100% ширины, т.е. прописать и для неё тоже
	

	align-self center // центровка самой секции
	max-width 100% // т.к центрируется, чтоб не вылезать за пределы

	// не должна быть растянута на всю ширину, т.к. контрастный фон нужно задать только центральному ядру, а не всему элементу
	// auto-margin()
	// auto-margin не работает, т.к. не задана ширина. Лучший вариант - навесить флекс на родителя
	// можно, конечно, добавить для центра обёртку core и центрировать её - такой подход позволит не зависеть от родителя (родители могут быть разными и всем придётся настраивать флекс тогда)


// fontscaler использовать для исправления ситуации там, где иначе беда (например заголовки меню скейлить когда нужно)
// breakpoint = 800px
// scaling = 'screen and (' bmin.tablet ') and (max-width:' mainMaxWidth ')'
// confined = 'screen and (min-width:' mainMaxWidth ')'


// как верстать главные блоки: с одной стороны, структура по принципу .l-shell.class .l-core позволяет реализовать почти любой вариант
// бэкграунд 100% + ядро по центру, ядро по центру с контрастным фоном, ядро по центру с выровненным слева контентом, 

.l-split
	b(transparent)
	grid(w: nowrap)

	.l-sidebar
		@media bp-sidebar
			display none

		// width 18%
		width 13em
		margin-right allPagesHorPadding
		space-items(sidebarBlocksSpacing, side: 'bottom')
		flex none
		fi(f: none)

		& > *
			fb(d: column, a: center)
			
		.sertificates
			img
				width 100%
				max-width 215px	

	.l-content
		// b(green)
		// display inline-block
		width 1px //костыль для IE, без указания width растягивает .l-content как блочный элемент во всю ширь
		fi(f: auto)
		// space-items(contentBlocksSpacing, side: 'bottom')
	
			
.l-split-product

	grid(w: nowrap)
	align-self stretch
	&__l
		// .product-image
			// b(green)
		// fb()
		grid(stack, g: 1em bottom, a: stretch)
		
		// grid(g: 1em right, a: stretch)
		.carousel
			// b()
			width 100%
			// +above(800px)
			// 	display none

		// @media smin.tablet
		// 	space-items(.5em, left)
		// 	align-items flex-start
		// @media smax.fablet
		// 	space-items(.5em, bottom)
		// 	flex-direction column 
	&__m
		// width em(210px)
		// width 16.5%
		width 15%
		
		margin-left 0 !important
		+below(800px)
			display none
		
	@media smin.fablet
		space-items(3%, left)
		&__l
			// width 52%
			width 54.5%	
			// em(500px)
			fi(f: none)
					
			// можно либо фиксированный размер flex(none) либо динамический (будет зависеть от правого столбца, фактически, от ширины строки названия)
			// если зафиксировать, названия с длинным словом (которое не будет помещаться в оставшиеся 40% будет оверфлоу)
	
	@media smax.mobile
		space-items(1em, bottom)
		// проценты почему-то не работают
		flex-direction column
		
	&__r
		// b(blue)
		// is-opaque()
		space-items(.5rem, bottom)
		flex auto



// .l-catalog
// 	width 100%
// 	// оверрайдит align-self center, заданное для секции. - должна быть задано всю ширь, иначе .flex-catalog не отработает как надо

// .new-products
// .discounted-products
// .related-products
// .catalog
// 	// можно присвоить им класс .l-catalog
// 	// или, более универсально, .l-wide или .l-shell
// 	// к классам в html можно относится как к удобному способу прописывать структурную наследственность вместо экстендов в css
// 	// b()
// 	// pos(relative)
// 	// padding 0 2rem
// 	width 100%
// 	// cent()
// 	// выравниваем по центру в случае ограничения содержимого по размеру; в принципе тут подошёл бы .l-shell
		
// 	// align-items stretch
// 	// т.к. .l-section прописывает флекс с align-items center
		
// 	//.l-catalog--columns
// 	// 	width 100%
// 	// 	columns(220px, g:1em)
// 	// 	& > li
// 	// 		margin-bottom 1em
// 	// 	.item
// 	// 		break()
	
/.l-catalog--flex
	// b(red)
	width 100%
	// max-width contentMaxWidth
	// for range, i in 'screen and (max-width: 379px)' 'screen and (min-width: 380px) and (max-width: 799px)'  smin.tablet
	for range, i in mobile fablet smin.tablet 
		@media range
			flex-gallery(i+1, 4%)
	// точки переключения должны также зависеть от разрешения картинок. 
	// Допустим при переходе к 1 картинке, посмотреть, какая у неё получается высота у imegelink, она должна быть не больше, чем разрешение, заданное в смарти для картинки каталога
	// по при этом надо смотреть, чтобы и надписи помещались по горизонтали
	// justify-content flex-start !important

.featured--catalog__main
	justify-content space-around !important

.l-top-stripe
	grid(stack, g: 1rem bottom)
	
.products-top
	@media bp-content-a
		grid(w: nowrap)
	&--left
	&--right
		cent()
	&--left	
		padding 20px
		background #c38488
		@media bp-content-a
			width 35em
		h1
			font(cl: white)
		
	&--right
		is-opaque()
		flex auto

/*#wrap	
	z-index 10 !important
	cent()*/





// PLUGIN OVERRIDES

		
// .slide
// 	size(100%)
// 	background-size cover
// 	background-repeat no-repeat

/*.slick-dots
	z-index 10*/

/*.slider
	margin-left 0 !important
	margin-right 0 !important*/

.zoomWindowContainer
	width 100% !important
	height 100% !important
	
.zoomWindow
	z-index 5 !important
	width 100% !important
	height 100% !important




// БОКСЫ КАРТИНОК

// структура .image-link не требует .l-overlay (подразумевается, что не задаётся image-link не задаётся бокс и высота остаётся гибкой)
// ограничивается только ширина 
.l-image-link
	img
		max-width 100%

// Для главного продукта:
// 1й вариант: image-link
// 2й вариант: можно заменить .l-image-link на .l-image-box и добавить .l-overlay.object-fit__cont, т.о. реализуем object-fit, 
// но при этом надо будет что-то делать с зумом, потому что зумится картинка будет без учета object-fit (как обрезать zoom-window?)

// 3й вариант: .l-image-box  .l-overlay.contain-image. Фиксированная пропорция картинки, картинка будет вписана в бокс.

		
.l-image-box
	ratio-box(1/1.5)
	
.l-overlay
	overlay()
	cent()
// верхний уровень - слайд. Бордеры/аутлайнв надо по логике вешать на него (выделение активного элемента)
// дальше - бокс картинки. не него вешается ratio-box.    Ниже может располагаться этикетка не органиченной резиновой высоты с контентом.
// третий уровень - оверлей.
// четвертый уровень - img.
// Т.к. используется ratio-box, высота элемента равна нулю, поэтому нужен элемент -оверлей сверху, в который тем или иным образом уже вписывается картинка
// если использовать object-fit. надо оверлею задать класс object-fit__cont.


// // обертку для картинок делаем по следующей схеме: максимум три элемента нужны (при )
// .l-box // задаёт пропорции для блока через ratio-box. 
// 	.l-image__cont // если выше .l-box, является оверлеем. Задаёт object-fit или max-width / max-height чтобы вписать картинку
// 		// если есть допустим ещё неймплейт, 
// 		img

// overlaю можно задать или .object-fit__cont, или .contain-image
// либо задать класс contained() - альтернатива .object-fit__cont

// CONTAIN IMAGE
.contain-image
	img
		contained()

// OBJECT-FIT
.object-fit__cont
	overflow hidden
	img
		object-fit cover
		height 100%
	&.compat-object-fit
		img
			display none

.compat-object-fit
	background-size cover
	background-position center center
