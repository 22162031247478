// bp-toolbar-1 = 700px
// bp-toolbar-2 = 480px

bpr-header--menu-fold = 800px // точка сворачивания меню в кнопку
bpr-header--account-fold = 480px // точка скрытия ссылок registration / login (оставляем один значок)

.cart

	grid(a: center)
	
	// &-text
	// 	width 5.5em
	// 	cent()
	// 	margin-right .5em

	// 	_f-regular()
	// 	font(ta: right, fw: bold)
	
	&-informer
		
			
		grid(a: center, g: .5em right)

		a
			pos(relative)

		// &__icon
		// 	// size(3em)
		// 	// width 2em
		// 	cent()
		
		&__text
			_f-regular--header()
			+below(bpr-header--account-fold)
				display none
			&:hover
				font(td: underline)
				
		&__count
			_f-regular--header()
			
		// // &__count--empty
		// 	font-size 1em
		// 	size(1.5em)
		// 	pos(absolute, t: -0%, l: 50%)
		// 	margin-left -(@width/2) + .25em
		// 	cent()

		// 	border-radius 50%

		// 	color white
		// 	background color3

		// &__count--empty
		// 	display none
		svg
			// fill color3
			size(100%)
			// height 40px //костыль для IE
		img
			size(100%)

.socials
	grid(g: 1em right, a: center)
	.social
		&__icon
			size(30px)
			border-radius 50%
			&:hover
				background alpha(color-0, .15)

.languages
	grid(g: 1em right)
	select
		_f-regular--header()
		// b(color-0)
		b(color--field-border)
		size(60px, 30px)
		padding 0 .5em
		text-align center
		background none
		option
			height 2.5em
			cent()

	/.lang-ru
		prepend-marker(url: img + 'flag-rf.png', g: .5em)
	/.lang-en
		prepend-marker(url: img + 'flag-uk.png', g: .5em)


//SCROLL-UPS
.scroll-up
	// b()
	// scroll-up(type: 2, color: #F26437, w: 2.5em)
	
	scroll-up(type: 2, color: color-0, w: 2.5em, bw: 5px)
	// fi(f: none)
	// background transparent
	// opacity 1 !important
	// size(3em)
	// background green
	
	// display none
	// @media bp-content-a
	// 	display none
	pos(fixed, t: 90%, r: 10%)

.header
	grid(stack)
	
	&__top
		height 50px
		background color--header

		grid(a: center)
		padding 0 1.5rem
		
		& > *
			margin 0 .5rem
			
		&--l
		&--r
			grid(g: 5rem right)
			
		.socials
			+below(bpr-header--menu-fold)
				display none
	
	&__mid
		cent()
		padding 2rem
		+below(bpr-header--menu-fold)
			display none
		
		.logo
			width 260px
			img
				width 100%
			
	

	/.account
		// prepend-marker(img +'account.svg', g: .5em, w: 1.7em, h: 1.7em)

		grid(a: center, g: 1em right)
		
		&__register
		&__username
			order 1
		&__login
		&__logout
			order 3
		&__link
			order 2
			

		a
			_f-regular--header()
			&:hover
				font(td: underline)
		// &--loggedIn
		// &--unlogged
		// &__registration
		// &__register
		// &__login
		// &__username
		&__link
			// border-radius 50%
			// width 23px
			// size(3em)
		&.userLoggedIn .account__link
			// border-bottom 3px solid white

		span
			+below(bpr-header--account-fold)
				display none


.toolbar
	// задаём позицию на случай fixed-позицинирования
	pos(relative, t: 0px, l: 0px, z: 100)
	
	width 100%
	// располагаем собственно тулбар и выпадающее меню друг над другом:
	grid(stack, j: space-around)
	
	background white
	border 3px solid color--header

	&__main
		pos(relative)
		padding .2em .5em
		height toolbar-main-height
		// grid(a: center, g: .5em left)

		+above(bpr-header--menu-fold)
			display none
		+below(bpr-header--menu-fold)
			flex auto
			justify-content space-between
		grid(a: center, j: center)
		
		
		toolbar-item-width = 5em
		.fixed_width_cont
			width toolbar-item-width
			cent()
			// b()

		.logo
			width 8em
			fi(f:none)
			img
				width 100%

		.menu-button
			menu-button(w: 2.5em, ratio: .75, cl: color-0, hcl: lighten(color-0, 30%))
			
			/*+below(bpr-header--menu-fold)
				pos(absolute, l: 1%, t: 50%)
				cent('self')*/
		
		.socials
			/*+below(bpr-header--menu-fold)
				pos(absolute, r: 1%, t: 50%)
				cent('self')*/
			+above(bpr-header--menu-fold)
				display none
			
					

	.main-menu
		grid(stack)

		+below(bpr-header--menu-fold)
			display none
		+above(bpr-header--menu-fold)
			align-self center


		& > .menu--depth-0
			// size(100%)
			// помещать грид в модули, но некоторые параметры выносить в лэйауты через переменные? например размер гаттера
			grid(a: stretch)

			+below(bpr-header--menu-fold)
				+grid()
					stack()


			& > li
				for order, i in  3 1 2 4 5 6
					&:nth-child({i+1})
						order order
				&.is-selected
				&:hover
					_is-selected(color-0)
						
				// cent() //не нужно, если a во весь li (size 100% для него)
				// line-height 2 //иначе на мобильном Хроме отображается по-другому; нужен cssreset?

				// @media bp-content-a
				// 	&:hover .menu--depth-1
				// 		// @media bp-toolbar-up
				// 		display flex //почему-то display: initial здесь не работает в IE11!!

				@media bp-content-a
					&:hover .categories-menu.menu--depth-0
						// @media bp-toolbar-up
						display flex //почему-то display: initial здесь не работает в IE11!!

				// 		// opacity 1
				// 		// походу если .menu--depth-1 не скрыто (display none), то это правило срабатывает при ховере над самим .menu--depth1
				// 		// поэтому с opacity поиграться не получится


				& > a
					// size(100%)
					width 100%
					padding .75em 1.4em
					pos(relative)
					// cent()
					_f-regular(fs: 16, cl: black)
					text-align left


			// apply-selected()

			// .categories-menu.menu--depth-0
			// 	_bg--dark(opacity: .8)
			// 	width 100vw
			// 	padding 3rem 8rem
			// 	grid()

			// 	pos(absolute, t: 60px, l: -1px, z: 100)

			// 	// transition 1s opacity
			// 	// opacity 0
			// 	// @media bp-content-b
			// 		// 	display none
			// 	display none

			// 	// font(ta: left, tt: initial, fw: initial)
			// 	//нужно оверрайдить стиль для главного меню, т.к. он каскадирует

			// 	& > li
			// 		& > a
			// 			_f-header(fs: 20, cl: white, tt: uppercase, fw: bold)
			// 			margin-bottom 1em
			// 			&:hover
			// 				font(td: underline)

			// 		padding .5em 1em

			// 	.menu--depth-1
			// 		space-items(.75em, bottom)
			// 		& > li > a
			// 			_f-regular(fs: 14.5, cl: white, tt: capitalize)
			// 			font(td: none)
			// 			&:hover
			// 				font(td: underline)

.divider
	height 1px
	width 100%
	max-width 600px
	background color-0
	auto-margin()
	margin-top 3rem
.footer
	bpr-footer--stack = 450px // точка переключения флекса на стек. bpr - для использования в формате rapture. Важная, т.е нужна почти всегда. Более мелкие брекпойнты модем не выносить в переменные
	
	bpr-footer--abs = 700px // не основная, но повторяется в двух местах
	
	height 100px
	padding .5em 3em
	+below(600px)
		padding .5em 1em
	
	+below(bpr-footer--stack)
		+grid(j: space-around)
			stack()
	
	_f-regular(fs: 14, cl: grey)
	
	grid(a: center, j: space-around, w: wrap)
	+above(bpr-footer--abs)
		justify-content center // глюк файрфокса: даже если блок позиционирован абсолютно, justify space-around его учитывает, как будто он в потоке
	pos(relative)
	
	// +below(fsdMQ(970px))
	// 	+grid()
	// 		stack()
	
	// вёрстка контейнера с двумя элементами, их которых один должен быть всегда по центру, а второй слева: "вырезать" второй pos(absolute) прилепить к левому краю
	// глюк файрфокса: даже если блок позиционирован абсолютно, justify space-around его учитывает, как будто он в потоке.

	/.copyright
		// margin-top 1em
		// order 2
		grid(g: 2rem right, w: wrap)
		+below(320px)
			+grid(a: center)
				stack()
		font(tt: capitalize)
	
	.socials
		+above(bpr-footer--abs)
			pos(absolute, l: 12%, t: 50%)
			cent('self')
			// transform translateY(-50%)


/*.sub-menu
	.menu--depth-0
		_bg--dark(opacity: .8)
		width 100vw
		padding 3rem 8rem
		grid()

		pos(absolute, t: 60px, l: -1px, z: 100)

		& > li
			& > a
				_f-header(fs: 20, cl: white, tt: uppercase, fw: bold)
				margin-bottom 1em
				&:hover
					font(td: underline)

			padding .5em 1em

	.menu--depth-1
		space-items(.75em, bottom)
		& > li > a
			_f-regular(fs: 14.5, cl: white, tt: capitalize)
			font(td: none)
			&:hover
				font(td: underline)*/
	
.banner
	// b()
	
	bpr = 1024px // bp-content
	
	X = 1200
	Y = 723
	
	extractText = false
	
	if extractText
		+above(bpr)
			ratio-box(X/Y)
	else
		// ratio-box(X/Y)
		+above(bpr)
			ratio-box(X/Y)
		+below(bpr)
			// height em(590)
			height em(400)

	.bg-slider
		bg-slider(1 2 3 4, 1024 1200, bpr: bpr)
		
		// если идёт обрезка картинки на маленьком вьюпорте, нельзя ориентироваться на ширину, т.к. привязка идёт к высоте,
		// т.е. нужно использовать в любом случае использовать большую картинку (ширина которой должна соответствовать брекпойнту, с которого начинается обрезка)

		+below(bpr)
			// если мы выносим надпись за слайдер, и нам нужно сохранить пропорции слайдера (без обрезки) нам уже нужно чтобы не весь banner был ratio-box'ом, а только .bg-slider
			// и оверлеем уже будет не .bg-slider, а его дочерний элемент, т.е slick-list
			
			if extractText
				// ЕСЛИ ХОТИМ СОХРАНИТЬ ПРОПОРЦИИ: (текст выносится за слайдер)
				ratio-box(X/Y)
				width 100%
				
				& > div
					overlay()
			else
				// ЕСЛИ ХОТИМ ОБРЕЗАТЬ:
				overlay()
			// 	// height em(590) // берём высоту картинки в точке брекпойнта
			// 	// берём высоту картинки, которая используется на ширине 1024 (в точке перелома), чтобы при изменении вьюпорта плавно включило обрезание картинки
			// 	// если не выносим надпись, то применить к banner (+below(bpr) height em(591))

	.overlay
		grid(stack, j: space-around, a: center)
		space-items(5rem, bottom)
		
		if extractText
			+above(bpr)
				//ниже брекпойнта выносим за пределы слайдера, поэтому оверлэй только выше бп
				overlay()
				z-index 99
		else
			+below(bpr)
				justify-content flex-end
				padding-bottom 2em
			overlay()
			z-index 99
			

	/.motto	
		max-width 100% // IE
		_f-motto()
		line-height 1em
		
		@media bp-content-a
			pos(absolute, 50%, 75%)
			width 5em
			transform translate(-50%, -50%)
		
		
		@media bp-content-b
			font-size 2em
			text-align center
			if !extractText
				// color white
				padding .5em
				background alpha(white, .4)
				
				b(color-0)
			
	/.button--to-catalog
		@media bp-content-a
			pos(absolute, 75%, 75%)
			transform translate(-50%, -50%)
		if !extractText
			@media bp-content-b
				a
					background alpha(white, .6)

/*.ixtlan
	// pos(absolute, r: -200px)
	// margin-right -300px !important
	// display none
	a
		font(cl: color2, fs: .8em)*/



//MAIN
/*.description
	.l-core
		grid(stack, g: 2rem bottom)
	&__main
		font(ta: center)
	// b(color-0)

	// padding 3em 2vw*/



// PRODUCT PAGES

.slick-list
	// центрируем контент в блоке на случай, если мало слайдов и слайдер не активен
	// display flex !important
	// justify-content center
	// grid(j: center)



.carousel
	b()
	// box-sizing content-box
	// чтобы не сжирались бордеры?
		
	&.product-gallery:not(.vertical)
		padding 0 2em

	carouselItemMargin = 8px
	pos(relative)

	/.product-gallery.vertical
		// b(green)

	// &__main
	// 	overflow visible
	// 	pos(relative)
	// 	grid(w: nowrap)

	// &__arrow
	// 	pos(absolute, t: 50%, z: 5)
		
	// 	transform translate(0, -50%)
	// 	cursor pointer
	// 	// width 1em !important
	// 	width = 1.5em
	// 	cl = green
	// 	transition opacity .5s
	// 	&:hover
	// 		opacity .6

	// 	&:before
	// 	&:after
	// 		content ''
	// 		display block
	// 		background color-0
	// 		size(.25em, 2em)
			
	// 	&--l
	// 		@extends .carousel__arrow	
	// 		pos(l: carouselItemMargin + 1)
	// 		grid(stack, a: flex-end)
			
	// 		width 1.25em
	// 		angle = 30deg
			
	// 		&:before
	// 			transform translateY(15%) rotate(angle)
	// 			transform-origin top
	// 		&:after
	// 			transform translateY(-15%) rotate(-(angle))
	// 			transform-origin bottom

	// 	&--r
	// 		@extends .carousel__arrow
	// 		pos(r: carouselItemMargin + 1)
	// 		grid(stack, a: flex-start)
	// 		// b()

	// 		// width 1.25em
	// 		angle = 30deg
			
	// 		&:before
	// 			transform translateY(15%) rotate(-(angle))
	// 			transform-origin top
	// 		&:after
	// 			transform  translateY(-15%) rotate(angle)
	// 			transform-origin bottom
				
	/.vertical .slick-list
		// эту фишку делаем, только если НЕ ПРИМЕНЯЕМ ratio-box на слайдах продукт-галереи
		// border-top 1px solid grey
		// border-bottom 1px solid grey
		
	/.vertical .slide-box
		// pos(relative)
		ratio-box(1/1.5)
		// неправильно отрабатыват кэш? position relative только выше 1024 ставит
		.slide
			overlay()
			.product-view
				size(100%)
				overflow hidden
			// img
			// 	object-fit cover
			// 	height 100%
		
	// /.vertical .carousel__arrow--l
	// 	transform rotate(90deg)
	// 	transform-origin 50% 50%

	// &__main:not(.slick-vertical) .slick-track
	// 	stretch-items()
		
	/*.slick-slide
		height initial !important*/

	// .slick-dotted.slick-slider
	// 	margin 0 !important
	// 	// переписываем стиль темы slick-theme.css

	// .slick-dots
	// 	// b()
	// 	margin .5em 0
	// 	cent()
	// 	pos(static)
	// 	// bottom -35px !important
		
	//можно прописать скрипт после slick, который при каждом нажатии на кнопки влево-вправо будет делать примерно следующее через style:
	/*.slick-active:not(:first-of-type)
		margin-left carouselItemMargin
	.slick-active:not(:last-of-type)
		margin-right carouselItemMargin*/

	// .slide-box //.slick-slide
		
	// 	/.js-gallery--productCarousel .slick-current .slide
	// 	/.is-slide-active
	// 		border 2px solid color-0 !important
	// 		// background grey

	// 	margin 0 carouselItemMargin
	// 	// fi(s: 0)
	// 	// ratio-box(66.6%) здесь нельзя задвать, т.к. будет считать % паддинга от slickslide
	// 	.slide
	// 		cursor pointer
			
	// 		&:not(.is-slide-active)
	// 		&:not(.slick-current)
	// 			border 1px solid grey
	// 			background #F3F3F3

	// 		// pos(absolute, t: 0, l: 0, z:1)
	// 		// size(100%)
	// 		cent()
	// 		background alpha(white, .5)

	// 		.product-view
				
	// 			// &:not(.is-slide-active)
	// 			// &:not(.slick-current)
	// 			// 	// border 1px solid grey

	// 			img
	// 				width 100%
	// 				// height calc(100% - 1px)
	// 				//-1 px, чтобы не жрал border снизу
	// 				// display block



		// width 23.25vw	//если используем slick variableWidth=false - он будет перезаписывать width
		//реальная ширина в разных браузерах получается чуть разная, т.к. походу они по-разному её считают??)

		// переписываем стиль height=100%, заданный в slick.css, который не даёт срабатывать align-items stretch: height initial !important или точно задаём высоту:
		// height @width * imageAspectRatio !important //в принципе, внутри .item работает и без important
		// ставим для страховки, чтобы максимально точно подобрать ширину - предотвращает искажение пропорций сжатием:

		//задаём фиксированную высоту в соответствии с заданной пропорцией... но тогда она будет одинаковой всегда, в slick будет менять ширину элемента и пропорция нарушатся при адаптивности
		// поэтому надо использовать свой респонсив (ширину подбираем эмпирически в vw); для slick ставим variableWidth = true, тогда он не перезаписывает ширину
		/*@media big
			// width 31.5vw	//не нужно, т.к. используем slick - он всё равно перезаписывает width
			height @width * imageAspectRatio !important
		@media tablet
			// width 48vw	//не нужно, т.к. используем slick - он всё равно перезаписывает width
			height @width * imageAspectRatio !important
		@media mobile
			// width 96.7vw	//не нужно, т.к. используем slick - он всё равно перезаписывает width
			height @width * imageAspectRatio !important*/
		//если использовать responsive в slick, тогда нужно imageAspactRatio прописывать в cfg, и добавить функцию которая будет при ресайзе
		//выставлять каждому элементу height, равный считанному из style width(который ставит slick) умноженный на imageAspactRatio
		//ещё можно попробовать провернуть трюк с паддингом (см. миксин responsive-video)

				// костыли для Хрома: при стретчинге картинок с height: auto (т.е. для которых задана только ширине) по высоте с помощью align-items они растягиваются до неимоверных размеров
				// если задать им height: 100% (что так же сработало бы в Файрфокс) ни остаются своей высоты (разной) если для род. контейера явно не задана высота.
				// В итоге, в Хроме никак нельзя растянуть картинки по высоте, явно не указав её.
				// В то же время для Мозиллы достаточно задать для img width 100%, а высоту- задав для a и img   height 100%,  или align-items  stretch для m-item и a (чтобы растянуть вложенный элемент)
				// альтернатива - использовать множественные мквери (см. миксин flexible countdown)

		/*&__name
			pos(absolute, b: 10%, l: 0%, z: 10)
			background-color alpha(color4, .7)
			transition all 0.5s
			width 100%
			height 10%
			font(fs: 2.3vw, tt: uppercase)
			text-shadow 1px -1px 3px color2
			&:hover
				background-color alpha(color4, .9)

			@media big
				font-size 21px
			@media mobile
				font-size 28px

			& > a
				color white
				display block
				height 100%
				cent()
			*/


// чтобы переменные были видны в потомках экстенда, их придётся объявлять глобально, т.к. если объявить в плесхоледер, будут не видны 
$carousel__arrow
	// общее для всех стрелок, цвет например и вн. вид
	transition opacity 0.5s
	cursor pointer		
	&:hover
		opacity 0.6

$carousel__arrow--hor
	@extend $carousel__arrow
	// 1 вариант расположения стрелок - абсолютным позиционированием в паддинге
	// 2 вариант - можем расположить стрелки, центрировав флексом + марджин на центр. контейнере.
	// - плохой вариант для горизонтального слайдера, стрелки будут уходить в overflow при сужении вьюпорта.
	pos(absolute, t: 50%, z: 5)
	cent('self', 'ver')

	width 1.5em
	// чтобы был активным только контур, убрать width в 0
	&:before
	&:after
		content ''
		display block
		background color-0
		size(.25em, 2em)
	

$carousel__arrow--vert
	@extend $carousel__arrow
	size(100%, 2.5em)
	
	pos(absolute, l: 50%, z: 5)
	cent('self', 'hor')
	
	background alpha(white, .6)
	
	&:before
	&:after
		content ''
		display block
		background color-0
		size(.25em, 2em)
		pos(absolute, l: 50%)
		// cent('self', 'hor')


$carousel
	.slide
		cursor pointer

	dotsSpace = 40px
	.slick-dotted //.slick-slider
		margin 0 !important
		// переписываем стиль темы slick-theme.css, по дефолту добавляет 30px снизу
		margin-bottom dotsSpace !important
	
	.slick-dots
		display flex !important // надо перезаписать дефолтный стиль block
		cent()
		height dotsSpace
		// pos(static), чтобы расположить в потоке. Но тогда Не получится выровнять стрелки, не учитывая точки
		// по дефолту у них pos absolute, т.е. может расположить относительно .carousel__main, которая имеет pos relative
		top 100%
	
	// img
	// 	max-width 100%
		
	// size(100%) НУЖНО В СВЯЗИ С ТЕМ, что иногда используются пустые дивы (с бэкграундом), которые не имеют размера и иначе схлопнутся
	// но вообще лучше такого избегать, или через ratio-box, или просто img
	// .slick-list
	// 	size(100%)


			
highlight-active()
	// Выделение активного слайда
	.slick-current
	.is-slide-active
		border 1px solid color-0 !important
		
	.slide			
		&:not(.slick-current)
		&:not(.is-slide-active)
			border 1px solid grey
	// проблема со сжиранием границ связана со Slick-slider. использовать границы одинаковой толщины!
	
.carousel
	arrowsClass = ".carousel__arrow"
	
	// &__main
	// 	width 100%
		
	// 	// если не задаём итемам рэтио-бокс, использовать l-catalog--flex (добавить класс к carousel__main)

	&--vert
		@extends $carousel
		grid(stack, j: space-between)
		pos(relative)
		
		// height 1000px
		// b()
		
		// .slick-list
		// 	// эту фишку делаем, только если НЕ ПРИМЕНЯЕМ ratio-box на слайдах продукт-галереи
		// 	border-top 1px solid grey
		// 	border-bottom 1px solid grey
		highlight-active()
		
		li
			margin 0.1em 0
			
		{arrowsClass}
		
			&--t
				@extends $carousel__arrow--vert
				top 0%
				&:before
				&:after
					top 20%
			&--b
				@extends $carousel__arrow--vert
				bottom -0%
				&:before
				&:after
					bottom 10%
				
			angle =60deg
					
			&--t
				// @extends $carousel__arrow--hor
				grid(stack, a: flex-end)
				

				&:before
					transform translateX(-350%) rotate(angle)
					transform-origin right
				&:after
					transform translateX(350%) rotate(-(angle))
					transform-origin left

			&--b
				// @extends $carousel__arrow--hor
				grid(stack, a: flex-start)
				
				&:before
					transform translateX(-300%) rotate(-(angle))
					transform-origin left
				&:after
					transform  translateX(300%) rotate(angle)
					transform-origin right
				

	&--hor //.slick-slider
		@extends $carousel

		//стрелки надо располагать здесь, относительно общего контейнера, т.к. внутри самой карусели их расположить не получится	
		shell()
		// располагаем стрелки абсолютно
		spaceForArrows = width = 40px
		slidesMargin = 0 1rem
		
		// центровка по вертикали, для вертикальной карусели не нужна. Нужно также отключать, если слайдера включена адаптивная высота
		.slick-track
			cent()
			
		// width initial !important // сбрасываем width, чтобы центрировать контент
		
		absolute-arrows(arrowsClass)
			pos(relative)
			padding 0 spaceForArrows
			{arrowsClass}
				arrowsPos = spaceForArrows/4			
				&--l
					pos(l: arrowsPos)
				&--r
					pos(r: arrowsPos)
					
		margin(slidesMargin)
			li
				margin slidesMargin
				
		absolute-arrows(arrowsClass)
		
		&.related-products__main
			margin(0 1rem)
			
		&.product-gallery__main
			margin(0 .5rem)
			highlight-active()
			
		&.product-big-image__main
			margin(0 1rem)
			.slide
				border 1px solid grey
			
			.slick-track
				display block // отключаем центровку
				
			/*img
				width 100%*/
			
		// делать ли шеллом (в 100% с центровкой) - задать ширину (например 100%) нужно обязательно (иначе не будет работать flex-catalog внутри)
		// но при этом если ограничить ширину ядра (.l-carousel__main, он же flex-catalog) то стрелки сместятся к краям шелла
		// поэтому ограничивать ширину можно только для всей карусели.
		// При необходиости можно добавить ещё один шелл снаружи
		
		{arrowsClass}
				
			// &--l
			// 	p(selector())
			// 	@extends $carousel-arrow--hor
			// 	triangle(direction: 'left', width: width, height: width*2, color: cl)
				
			// &--r
			// 	@extends $carousel-arrow--hor
			// 	triangle(direction: 'right', width: width, height: width*2, color: cl)

			angle = 30deg
					
			&--l
				@extends $carousel__arrow--hor
				grid(stack, a: flex-end)

				&:before
					transform translateY(15%) rotate(angle)
					transform-origin top
				&:after
					transform translateY(-15%) rotate(-(angle))
					transform-origin bottom

			&--r
				@extends $carousel__arrow--hor
				grid(stack, a: flex-start)
				
				&:before
					transform translateY(15%) rotate(-(angle))
					transform-origin top
				&:after
					transform  translateY(-15%) rotate(angle)
					transform-origin bottom	

.banner 
	.slick-list
	.slick-track
		size(100%)
		// fb(a: center) // убрать это, если у нас adaptiveHeight на карусели
		//выравниваем вертикально по центру, если картинки разные по высоте


	
// PRODUCTS PAGE

// СДЕЛАТЬ ЭТО МИКСИНАМИ

// item(imageRatio, )




over = true	
.item
	// b()
	if (over)
		pos(relative)
		// при over-лэйауте nameplate должен быть вровень с imagelink, поэтому паддинг не вариант
	if (!over)
		grid(stack, 2rem)
		// padding .8em

	/.recommended
		pos(absolute, t: 2%, r: 2%)
		width 12%

	/.new
		_f-regular(fs: 14, cl: red)

	
.entry__title
	// font-style italic
	display inline-block //иначе не работает margin
	margin-right .5em
	color color-0

.nameplate
	// b(red)
	grid(stack, a: center, g: .5em bottom)
	width 100%
	if (over)
		pos(absolute, b:0)
		
	background alpha(white, .8)
	padding 1em
	

	&__name
		width 100%
		text-align center
		a
			_f-regular(18)
			font-weight normal
			color font-color


/*$marker
	pos(absolute, l: -.8em)
	f-marker(4em)
	border-bottom 1px solid bkgclr2

group-1 = 20%
group-2 = 40%
group-3 = 60%
group-4 = 80%

for i in 1 2 3 4
	.marker--{i}
		@extends $marker
		top lookup('group-' + i)*/


// PRODUCT PAGE

//product
$_product-module
	// font(fs: .8rem, ta: left)

.product
	&-presentation
		space-items(3rem, bottom)
		align-self stretch
	&-name
		_f-header(fs: 32)
		/*@media fablet
			font-size 3.5vw*/
		//вообще тут нужен fontscaler - проблема имено в том что не помещается самое длинное слово и тянет весь блок за собой
		// margin-bottom 2em !important

	//фактически если тут стили пусты, то он не будет в css, как и плейсхолдер. Но, в отличие от плейсхолдера, если стили прописать, они пойдут в  css, даже если сам блок не существует
	&-info
	//фактически #p-product .nameplate
		@extends $_product-module
		_f-regular(fs: 18)
		grid(stack, a: flex-start, g: 2em bottom)
		@media bp-product-b
			align-items center

		.entry
			text-align left
		// fb(d:column, j:flex-start)
		// А вот эти стили уже относятся ко внутреннему содержимому, а не к лэйауту
		// и надо бы их прменить к .product
		// padding 1.5vw
		width 100%
		
		.sku
			font(cl: color-0)

		.variants
			// grid(g: 2em right, w: wrap)
			grid(stack, 2em bottom)
			// margin 1rem 0
			// align-self center
				
			.variant
				// b()
				wrap-margin = .75em 
				margin-top wrap-margin
				margin-bottom wrap-margin
				grid(stack, g:1em bottom)
				
				&__title
					font(cl: color-0)
			label
				text-transform capitalize
			
			selectorHeight = 2.5em
			/.amount
			/.sizes
			/.colors
				// min-height selectorHeight
				height selectorHeight
				// min-width 10em
				// cent()
				// костыль для Хроме, он не растягивает select на 100% по высоте
				
				border 1px solid color--field-border
				
				span
					size(100%)
					cent(d: ver)
					padding 0 1em
				select
					size(100%)
					cent()
					padding 0 1em	
					cursor pointer
					
					_f-regular(fs: 18)
					
					// padding 0 0 !important
					border 0 none
					// border 1px solid color--field-border
					background transparent
					option
						padding 0 1em
			
			/.colors
				colorsHeight = selectorHeight
				min-height colorsHeight
				grid(g:.5em right)
				// grid()
				input
					display none
				label
					display inline-block
					cursor pointer
					pos(relative)
					size(colorsHeight)
					margin .1em // если есть вероятность что нужен будtn flex-wrap
					border 1px solid color--field-border

					&::before
						content ''
						cent()
						overlay()

				input[type=radio]:checked + label:before
					// content "\2022"
					background url(img+'checked.svg') center / 60% no-repeat
					// box-shadow: .1em .1em .1em .1em rgba(0,0,0,0.75);
					
					// background-size 80%
					// size(100%)
					// font(cl: white)

		.features
			space-items(.5em, bottom)
			// align-self center

		/*.status
			width 100%*/
				

/.to-cart
	
	.price__value
		// font(fs: ks-em(20), fw: bold, cl: #641F68)
		grid(g:.25em right)
		f-regular(cl: color-0, fs: 24)

	.price--compare
		// .price__value
		// 	color red
		& + .price--actual 
			.price__value
				color green
	.variant-price
		grid(g:1em right)
	
	
	.nameplate &
		grid(stack, a: center)
		width 100%
		_f-regular(18)

		.pricing
			.price
				a
					font(cl: color-0, td: underline, ta: center)
					display inline	

	.product-info &
		grid(stack, a: flex-start, g: 2em bottom)
		_f-regular(cl: color-0)
		
		.pricing
			&__cont
				// grid(a: baseline, g: .5em right)
				grid(a: center, g: 1em right)
		.price
			font(fs: 1.2em)
					
	/.buyme
		buyme()
		// buyme(green, 'cart.svg')
		&__text
			prepend-marker(img + 'cart.png', g: 1em)
			
		/.ui-effects-transfer
			b(color-0)
			background lighten(color-0, 90%)
			
		align-self initial




	//ВНЕШНИЕ МОДУЛИ, НЕ НАСЛЕДУЮТ СТРУКТУРУ ОТ .product-info или .product,
	&-image
		@extends $_product-module

		// size(70%)
		// auto-margin()
		// width 600px

		.l-imagelink
			padding 2%
			background white

		img
			width 100%
			position static !important // костыль для ev-plus
			// width 60vw

		/.product-image
			position relative
		/.zoomWrapper
			// z-index 99 !important
			// position relative
		/.toolbar
			z-index 1000 !important


	// &-gallery
	// 	@extends $_product-module

	// 	// тут могут быть такие варианты:
	// 	// свободная галерея на флексе space-around;
	// 	//	ограниченная вертикальная в 1 колонку (как янченко); ограниченная горизонтально в 1 ряд (с прокуруткой);
	// 	// b()
	// 	// fb(j: space-between)

	// 	// 2: на колонках типа : columns(250px, g: 1vw) но, так не задашь миним. кол-во колонок
	// 	// 2а: колонки  + mediaquery
	// 	// проблема в том, как отцентрировать галерею, если элементов вдруг меньше, чем рассчётное число колонок,
	// 	// не задавая ей при этом фиксированный размер. auto-margin не работает (размер не фиксирован), center-items
	// 	// приводит к сжатию элемента с columns(150px, g: 1vw) до одной колонки шириноц 150px
	// 	// и вот это вот реальная проблема

	// 	//вар 3: флекс, но j: flex-start и space-items -cycle + media-query, колв-во колонок задаём %item width

	// 	&__main

	// 		fb(j: space-around, a: center, w: wrap)


	// 		a
	// 			// width 20%
	// 			// max-width 200px
	// 			// предотвращаем схлопывание в одну картину шириной:
	// 			/*@media smax.mobile
	// 				width 46%*/
	// 		img
	// 			// margin 1%
	// 			max-width 100%




.browsed
	border 1px solid black
	has-bkg-white()
	min-width 200px

	&__title
		font(fs: 1em)
		width 100%
	&__main
		browsedItemWidth = 120px
		fb(d: row, w: wrap)
		@media mobile
			// height 100vw
			height 320px
			// margin-left 1em
			overflow scroll

		product(width: browsedItemWidth, imgHeight: browsedItemWidth * 1.25, description: false)

		img
			border 1px solid black

/*.to-cart
	// margin-top 1em
	//фиксирует по центру чтобы не съезжала
	center-items(flex)
	display inline-flex // т.к. блочный флекс растягивается на всю ширину, если её явно не задать
	background url(img + "/cart_white.png"), color2
	background-repeat no-repeat, repeat
	background-size 20%
	background-position 90%

	input
		font(cl: color1, fs: 1em, ff: mainFont, tt: none)
		border 0 none
		padding .4em 2.6em .4em .8em
		background transparent
		cursor pointer
		@media smaller
			font-size 1.8vw*/

.product-kits
	width 100%
	&__main
		grid(stack, g: 2em bottom)
		width 100%
		// fb(d: column)
		// space-items(2em, bottom)
		
		.kit		
			width 100%
			padding 1em
			has-bkg-white()
			// b()
			grid(stack, g: 1em bottom)
			// fb(d: column)
			// space-items(1em, bottom)
			
			&__description
				font(ta: center)
				
			&__products
				for range, i in mobile smin.fablet
					@media range
						flex-gallery(i+2, 3%)
				justify-content space-around !important
				li
					margin .25em 0
					b(grey)
					&:not(:first-child)			
						pos(relative)
						&::before
							content '+'
							pointer-events none
							color green
							font-size 8rem
							pos(absolute, l: -5%, t: 0%, z:5)
				
				
				/*.divider:last-of-type
					display none
				
				.divider
					// b()
					center-items()
					margin 0 2em
					p
						font(cl: green, fs: 10em)*/
			
			&__to-cart
				// b(grey)
				grid(stack, g: 1em bottom, a: flex-start)
				// font(fs: 1.1em)

			&__pricing
				grid(stack, g: 1em bottom)				

			.buyme
				width 100%
