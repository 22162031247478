/*APPEARANCE CLASSES*/

// VIEWS

// не абстрагировать цвета и шрифты в переменные vars, пока это действительно не нужно
_bg--dark(background = #3a3a3a, opacity = 1)
	background alpha(background, opacity)
	color white
	a
		color white

_bg--bright(background = white, opacity = 1)
	background alpha(background, opacity)
	/*color white
	a
		color white*/
	
double-border(color)
	border-top 1px solid color
	border-bottom 1px solid color

shadow(color = alpha(black, .75))
	box-shadow 4px 2px 5px 2px color

underlined()
	pos(relative)
	&::after
		content ""
		pos(absolute, l: 0px, b: -8px)
		width 100%
		height 1px
		background-image border-gradient
	

underline(w)
	border-bottom w solid color3


_is-selected(color)
	// border-bottom 5px solid lighten(color, 40%)
	// pos(relative)
		
	// &::before
	// 	content ''
	// 	display block
	// 	// width 2.5rem
	// 	// height @width
	// 	pos(absolute, b:0, r: 50%)
	// 	triangle(top, 1.25em, 1.25em, color)
	// 	// cent(translate) // как передать аргументы? как в gutter?
	// 	transform translateX(50%)
	a
		color color
	// border-bottom 5px solid lighten(color, 40%)
	// background alpha(color, .7)
	// background lighten(color, 40%)
	// background darken(color, 20%)

	
apply-selected(color)
	& > .is-selected
		selected(color)

is-opaque()
	background alpha(white, .75)
	padding 20px	
		
$_has-border
	b(#7b7b80)

$_has-contrast
	is-opaque()


// STATES
			
.is-hidden
	display none	
.is-centered-txt
	text-align center	
/*.is-padded
	margin 0 isPaddedValue*/
.is-flex-container
	display flex	

		
/*.main-menu .sub-menu .is-selected
	@extends is-selected
	& > a
		has-bkg-white()*/


.is-fixed
	// box-shadow 0px 2px 12px 0px rgba(0,0,0,0.75)
	position fixed !important
	.scroll-up
		display initial !important
	+above(800px)
		.toolbar__main
		.toolbar-menu	
			display flex !important
	@media bp-content-a
		// display flex !important
		visibility initial !important
		
.is-hidden
	visibility hidden	

