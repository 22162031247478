// PAGES // делаем свой лэйаут-"wrapper" для каждой страницы, если надо привязать стиль к странице, вкладываем селектор в него

.l-wrapper
	min-height 100vh
	grid(stack)
	//чтобы прижать футер к низу 100vh

// скрываем страницы по id
.page_124 // главная
	display none !important


$_all-pages
	// margin-top headerHeight
	
	// Тут два варианта:
	// ДАНО: большинство блоков должны иметь некий марджин с краю, но некоторые должны быть во всю ширь.
	// в то же время, сверху и снизу страниц практически всегда должен быть определённый зазор, который мы можем задать вертикальным паддингом для всех страниц
	// если задать гор. паддинг всем страницам, проблематично будет создать растянутый во всю ширь элемент.
	// поэтому вместо этого задаётся марджин всем элементам & > * через margin-left allPagesHorPadding;
	// если элемент должен быть во всю ширь, его марджин перекрывается со сбросом в ноль margin 0
	// как правило это прописывается для #p-main .l-main.
	// если марджин .l-main сброшен в ноль, то как правило надо прописать .l-main & > * margin: allPagesHorPadding для элементов внутри него (кроме того элемента, который должен быть растянут, для него опять же сбросить марджин)
	// Итого у нас фигурируют: отступы по вертикали - allPagesVertPadding, contentBlocksSpacing, sidebarBlocksSpacing;
	// отступы по горизонтали - allPagesHorPadding (фактически - марджин элементов страницы). Это всё имеет смысл задавать в rem и потом в html переопределять в vw, если нужно

	min-height 100vh
	//анти-схлопывание: должна быть задана граница, хотя бы transparent
	b(transparent)
	
	// СПЕЙСИНГ ЭЛЕМЕНТОВ
	grid(stack, j: flex-start, a: center, g: contentBlocksSpacing bottom)
	// выставляем align-center, чтобы элементы с ограниченнием по ширине (например текстовые descroption), автоматически располагались по центру
	// однако, ширина элементов при этом будет являться неопределённой (max-width не задаёт ширину, т.е она не рассчитывается браузером и width по сути не определено)
	// если бы стоял дефолт align-items: stretch, это было бы равносильно width 100% и width была бы определена.
	// поэтому если используем align-items: center, ставим дополнительно всем элементам width 100%
	

	// ОТСТУПЫ ДЛЯ СТРАНИЦЫ
	padding allPagesVertPadding 0
	& > *
		// b()
		width s('calc(100% - 2*%s)', allPagesHorPadding)
		// padding-left allPagesHorPadding
		// padding-right allPagesHorPadding		
		// margin-left allPagesHorPadding
		// margin-right allPagesHorPadding
		// для margin проще прописать оверрайд (просто оверрайдим margin 0 для конкретного элемента, который должен быть во всю ширь)
			
		// ограничение контента по ширине. Делаем тоже для детей, чтобы можно было оверрайдить для отдельных элементов
		if allPagesContentMaxWidth
			max-width allPagesContentMaxWidth
			// auto-margin()
			// если выравниваем с помощью margin auto, это лишает нас возможности задать фиксированный марджин (который нужен на мобильном лэйауте), т.к. margin auto будет его перезаписывать.
			// поэтому используем grid(stack, a: center)
			// но тогда другая проблема: мы всё равно не можем задать "буферный" марджин, 
			// т.к. элемент (например, .main) с align-self center и width 100% будет растягиваться на 100% вьюпорта (маджины будут вылезать за вьюпорт, как будто их и нет)	

	.l-content
	.l-main
		// width 100%
		grid(stack, g: contentBlocksSpacing bottom)
		//нужно a:center, чтобы блоки с ограниченной шириной центрировались внутри main. В общем, main ведёт себя как и $_all-pages, т.е. как является подобёрткой с тем же лэйаутом
		// может находиться непосредственно внутри page, поэтому не может иметь ширину 100% (см. выше про центрирование) Но в принципе можем не ставить align-items: center (это уже зависит от конкретного случая)

		// width 98%
		// align-self stretch
	
	// bg(bi: url(img + 'bg--description.png'), bp: bottom, bs: contain)

#p-main
#p-page
	@extends $_all-pages

#p-brands
#p-blog
#p-post			
#p-feedback
	@extends #p-page
	.contacts .l-core
		grid(stack, g: 1rem bottom, a: center)
	

$_form-pages
	@extend #p-page

#p-cart
#p-order
#p-login
#p-user
#p-register
#p-comments
#p-password-remind
#p-wholesaleorder
#p-shopfinder
	@extends $_form-pages

$_catalog-pages
	@extend $_all-pages
	
#p-products
#p-product
	@extends $_catalog-pages

// ОВЕРРАЙДЫ ДЛЯ ОТДЕЛЬНЫХ СТРАНИЦ

		
#p-order
#p-cart
	.l-main
		space-items(6rem, bottom)	
		// нужен одинаковый спейсинг для всех элементов независимо от их размера шрифта, опционально зависимый от ширины вьюпорта
		// хотя, когда в em - удобнее управлять (можно контролировать все размеры заданием шрифта на корневом элементе)
		// но если задаём в em, Для h1 например получается слишком большой отступ. Этого можно избежать, обернув h1 в div
		// задавать в rem!

#p-main
	// background initial !important
	// padding 0 !important
	// border 0 none !important
	// .l-main
	// 	margin 0 auto
		
		
	// padding-bottom 0 !important
#p-page .description--page
	img
		// width 100%
		max-width 100%
		width auto !important
		height auto !important
		// auto-margin()
			
	h1
		margin-top 1em
	// b()
	// padding 2em
	// border-width 4em
	// // border-image url(http://www.clipartbest.com/cliparts/9cz/EGX/9czEGXARi.svg) 236 168 234 repeat
	// // border-image url(http://www.clipartbest.com/cliparts/9cz/EGX/9czEGXARi.svg) 15% stretch
	// // border-image url(http://i.istockimg.com/file_thumbview_approve/23563543/6/stock-illustration-23563543-simple-vector-vintage-frame.jpg) 25% repeat
	// // border-image url(http://www.wpclipart.com/page_frames/simple_ornamental/bold_announce_frame_border.svg) 15% stretch
	// border-image url(http://www.wpclipart.com/page_frames/more_frames/more_frames_5/showcard_border.svg) 325 repeat
	
	// // border-image-slice: 325 325 325 325;
	// border-width 5px
	// border-image-width 1
	// // border-image-outset: 0px 0px 0px 0px;
	// // border-image-repeat: repeat repeat;
	// border-image url("http://www.wpclipart.com/page_frames/more_frames/more_frames_5/showcard_border.svg") 325 repeat; 

	// border-image url(img + 'frame.jpg')
	padding 5em
	@media bp-content-b
		padding 2.5em
	// border-width 5em
	// border-image url("http://www.wpclipart.com/page_frames/more_frames/more_frames_5/showcard_border.svg") 325 / 5em repeat;
	border-image url(img + "border-3.svg") 320 / 12em repeat
	@media bp-content-b
		border-image url(img + "border-3.svg") 320 / 9em repeat
	// border-image url(img + "border.svg") 330 / 5em repeat;
	// border 5em solid black
	//border-image-width работает в шортхэнде border-image после / ; если укзать его отдельно вместе с border-image, оно перезапишется
	// чтобы создать отступ, можно задать либо border-width (может служить фоллбэком) или паддинг

