// CART PAGE
	.cart-block
		grid(stack, g: contentBlocksSpacing bottom)
		width 100%
		
	.purchase
		padding .5em
		border-bottom 1px solid #F7F7F7
		grid(a: center, j: space-around, w: nowrap)
		
		+below(600px)
			+grid()
				stack()
		
		& > *
			margin 1em
			// white-space nowrap

		&-l__item
			& > *
				margin .5em
			// space-items(1em, right)
			// width 16em
			flex auto
			min-width 12em
			// будет занимать всё свободноек место, но не будет сужаться меньше 16em
			// гарантирует, что будет одинакового размера (для всех покупок)
			
			fb(a: center)
		
		
		&__image
			width 100px
			img
				max-width 100%
		&__info
			fb(d: column, j: center)
			// space-items(.5em, bottom)
			a
				margin-bottom 1em
			span
				margin-top .25em
				font-size .85em
			
		&-l__pricing
			fb(w: wrap, a: center)
			& > *
				margin .3em
			// space-items(1em, right)

		&__price
		&__total-price
			white-space nowrap
			// width 6em
		&__total-price	
			font-weight bold
		// &__price
		// &__amount
		// &__total-price	
		&__remove
			a
				size(2em)
			img
				size(100%)
	
	.total-price
		padding 1em
		align-self center
		font(ta: center, cl: color--total-price, fw: bold, fs: 18px)
		display inline-block


.orders-history
	width 100%
	&__main
		space-items(1em, bottom)
	.order
		grid(g:1em right)
		
.user
	grid(stack, g: contentBlocksSpacing bottom)
	
.password-change
	align-self center
	&:hover
		font(td: underline)


.login
	// grid(stack, a: center, g: 3em bottom)
	&__pass-reminder
	&__register
		&:hover
			font(td: underline)
		align-self center

		// text-align center
		// margin-bottom 2em
			
// .account__register
// 	auto-margin()
// 	text-align center
// 	&:hover
// 		font(td: underline)


.checklist
	space-items(.75em, bottom)
	
// ORDER PAGE
.order
	&-info
		// grid(stack, g: 1em bottom)
		// space-items(1em, bottom)
		&__main
			space-items(1em, bottom)
			font(tt: lowercase)
	
	&-status__message
		font(ta: center)

	

.payment
	grid(stack, a:center, g: 2em bottom)
	.payment-form
		grid(stack, g: 2em bottom)

		
.payment-review
	space-items(1em, bottom)
	&__method
		selector( )
		space-items(1em, bottom)



// FORMS
	
.form--textFields
	font(tt: capitalize, cl: color--field-border)
	grid(stack, g: 2em bottom)
	

	.legend
		font(tt: none, ta: center)
		
	.fields-block	
		+below(900px)
			grid(stack, a: center, g: 2rem bottom)
			.field
				width 100%	
		
		+above(900px)
			flex-gallery(2, side-margin: 5%, vert-margin: 1em, sel: 'div')
			justify-content space-around
			// для работы должна быть задана ширина у родителя (т.е. .l-core выше 900px не сможем центрировать)
		
		
		
		.field
			grid(stack, g:.5em top)
			input
			textarea
				border 1px solid color--field-border
				padding .3em .8em
			input
				height 50px
			textarea
				height 150px
			
			
			placeholder()
				text-align right
				font-size 1.2em
				// opacity 1
				vertical-align top
				// margin-right 2em


			input.input--required:-ms-input-placeholder
				placeholder()

			input.input--required::-webkit-input-placeholder
				placeholder()

			input.input--required::-moz-placeholder
				placeholder()
			textarea.input--required:-ms-input-placeholder
				placeholder()

			textarea.input--required::-webkit-input-placeholder
				placeholder()

			textarea.input--required::-moz-placeholder
				placeholder()
				
			//селекторы по отдельности, т.к. вместе почему-то не срабатывает
			
			// content 'x'
			// size(1em)
			// pos(absolute, r: 10%, t: 50%)
			// cent(m: self)
	
	.captcha
		width 100%
		grid(a: flex-end, g: 2em right)
		
		.field
			flex auto
		img
			margin-bottom 4px
	
	.button--confirm
	.button--captcha-confirm
		align-self center
		padding-left 4em
		padding-right 4em
		+below(900px)
			width 100%


.register
	.l-block
		grid(stack, g: 1.5rem bottom) 	


.confirm
	confirm(b: 1px solid color--field-border, fixHeight: false)
	+below(500px)
		+confirm()
			vertical()		

// .field
// 	// field(none, inputWidth: 0.80, width: 780px, extend: $_has-border $_bg-dark)
// 	// field(m: none, inputWidth: 0.80, width: 780px, b: 1px solid color--field-border)
// 	@media smin.tablet
// 		+field()
// 			row()
// 	@media smax.fablet
// 		+field()
// 			column()
// 	/*label
// 		font(tt: capitalize)*/	

// .form
// 	&--textFields
// 		space-items(1em, bottom)
		
// 		/.fields-block
// 			auto-margin()
// 			// max-width 800px
// 			grid(stack, g: 1em bottom)

// TODO реализовать наследование для форм через экстенды



// ФИЛЬТРЫ И СОРТИРОВЩИКИ

.sorters
.pricers
.feature-filters
	width 100%

inputStyle()
	border 0 none
	background bg--buttons
	f-regular(12)
	padding .5em

.pricers__buttons
	fb(j: space-between)
	input
		inputStyle()
		
		&:hover
			background darken(@background, 5)
		
.filters__main
.sorters__main
.pricers__main		
	width 100%
	space-items(.5em, bottom)
	text-align center	

.pricer
.sorter
.filter--selector
	// width 80%
	// padding .5em
	fb(j: flex-start, a: center)

	label
		f-regular(14)

	input
	select
		margin-left 1em
		flex auto

		inputStyle()
	

//TEST
// .filters
// 	grid(stack, g: 2em bottom)
// 	&__main
// 	&--features
// 		grid(stack, g: 3rem bottom)
	


// .filter
// 	grid(stack, g: 2rem bottom)
	
// 	&__h
// 		font(ta: left)
// 	&__main
// 		grid(stack, g: 1rem bottom)


// .filter--price
// 	@extend .filter
	
// 	input
// 		width 3.5em
// 		font(ta: right)
	
// 	.display
// 		grid()
// 		&__from
// 		&__to
// 			grid(g:.25em right, a: center)

//TEST


//DIANA			
.filters
	cent()
.filter
	&-features
		align-self center
		grid(g: 2em right)
	// &__name
	// &__main
		
	&--checkboxes
		selectorHeight = 1em
		min-height selectorHeight
		grid(a: center, g: 2em right)
		// grid()
			
		.filter__main
			grid(g: 2em right, w: wrap)
			justify-content space-between
		input
			// visibility hidden
			display none
		label
			// display inline-block
			cursor pointer
			// pos(relative)
			// size(selectorHeight)
			margin .1em // если есть вероятность что нужен будtn flex-wrap
			// border 1px solid color--field-border
			font(tt: capitalize)
			
			+prepend-marker(g: 1em, w: selectorHeight, h: selectorHeight)
				background color-0
				cent()

		input:checked + label::before
			// content "\2022"
			// background url(img+'checked.svg') center / 60% no-repeat
			background transparent
			color color-0
			content '\2713'
			b(color-0)
			padding-bottom .1em
			// background green
			
		.filter--checkboxes__option
			input
			label
//DIANA
	
.message
	/$_message--availability
	/$_message--wide
		display block
		width 100%
		margin 1em auto
		text-align center
		text-transform initial
	&--error
		@extends $_message--wide
		color red
	&--success
		@extends $_message--wide
		color green
	&--info
	&--no-content
		@extends $_message--wide

	&--is-available
		@extends $_message--availability
		color green
	&--is-unavailable
		@extends $_message--availability
		color red

	&--has-no-price
		font-size 0.8em
		color color-0

.button
	buttons-padding = 1em 1.5em
	
	/$_button
		// @extends $_has-border
		
		background bg--buttons
		&:hover
			background alpha(color-0, .2)
			// 	background lighten(bg--buttons, 20)

		align-self center
		
		// min-width 10em
		// cent()
		
		border 1px solid color-0
		
		
	// кнопкам, которые содержат внутри ссылку (обычно растянута на всю кнопку), надо задавать паддинг и font для a ()
	
	/$_button--link
		a
			padding buttons-padding	
			_f-regular(cl: color-0)
			display inline-flex
		
	/$_button--input
		cursor pointer
		padding buttons-padding
		_f-regular(cl: color-0, tt: uppercase)
	
	&--to-catalog
	&--more
		@extends $_button, $_button--link
		font(tt: none)
		
	&--add-comment
	&--logout
	&--buyme
	&--confirm
	&--captcha-confirm
	&--filter
	/.checkout_button
		@extends $_button, $_button--input
		// max-height 45px
		
// BLOG
.blog
	space-items(2em, bottom)
.post
	&__img
		auto-margin()
		max-width 100%
		
		@extends $_has-contrast
	
	space-items(1em, bottom)


	&-short
		space-items(20px, bottom)
		& > a&__aimg
			display block
			width 100%
		img
			auto-margin()
		&__more
			font(ta: right, st: italic)
			auto-margin()
			&:hover a
				color color2

.comment
	margin 3rem auto
	// has-bkg-white()
	// padding 1vw
	grid(g: 1rem right, w: nowrap)
	// fb(j: flex-start, a: flex-start)
	+below(480px)
		+grid(a: center, g: 2rem bottom)
			stack()
	
	& > img
		display block
	&__image
		margin-right 2rem
		
	&__main
		width 80%
		grid(stack, g: 1.5rem bottom)
		// fb(d: column, j: space-between, a: stretch)
	
	&__header
		grid(stack, g: .5rem bottom)
		+below(480px)
			auto-margin()
			text-align center
	&__name
		font-weight bold
	&__date
		display block		
	&__text
		margin .3em 0
		// display block
	&__reply
		+below(480px)
			auto-margin()
.comments
	width 100%
	// max-width 800px
	margin 0 auto
		
.add-comment
	grid(stack, g: 2rem bottom)
	// cent()
	// flex-direction column		

	&__window
		section()
		width 80vw
		max-width 700px	
				
	&__form
		width 100%
		
		.input-block
			grid(stack, g: .5em bottom)
			// fb(d: column)
			// space-items(.5em, bottom)
		
		space-items(1em, bottom)
		
		.field
			grid()
			// fb(j: space-between, d: row, w: wrap)
			// width 90%
			input
			textarea
				width 100%
			
			label
				cent()
			.upload-image
				border none
				width initial
		textarea
			height 200px			
		
		// .confirm
		// 	margin-bottom 2px // небольшой headroom, чтобы нижний outline вокруг confirm не проглатывался border-box ом
			
/*.shopfinder
	width 100%
	// b(green)
	fb(d: column, a: stretch)
	// ratio-box(50%)
	
	.map
		// b()
		height 50vh
		.gm-style
			b()

	.filter
		margin-top 1.5vw
		fb(d: column)
		
		select
			b()
			width 100%
			max-width 320px
			height 2em
			margin-bottom 1.5em

		.shop
			margin 3vw auto
			p
				text-align center
			a
				margin .5vw 0
		/*.shops
			width 300px
			padding 4px
			height 100%
			overflow auto*/*/

.breadcrumbs
	spacing = 1em
	&__main
		grid(j: flex-start, w: wrap)
	/*li
		grid(g: spacing left)*/
			
	_f-breadcrumbs()
	//шрифт с нормальными стрелками:
	// span
	// 	font(ff: Arial)
	// edit()
	// padding breadcrumbsPadding 0
	// font(fs: .9em)
	li
		// b()
		grid(a: center)
	a
	p	
		// display inline-block
		margin .25em .5em

.pagination
	
	color--pagination__number = transparent
	color--pagination__arrow = color-0
	color--pagination__selected = color-0
	color--pagination__border = color-0
	color--text = color-0
	
	fb(j: center, a: center)
	margin-top 2vw
	_f-header(fs: 18)

	_if-hovered()
		&:not(.is-selected):hover
			transform scale(1.1)
			// background alpha(color--pagination__selected, .2)

	
	//следующий рулсет, одинаковый для всех элементов, можно или применить стили ко всем ссылкам, или сделать это через экстенд плейсхолдера .pagination__link
	a
	// &__link //плейсхолдер!
		cent()
		padding .2em
		margin .5em
		color color--text

	&__number
		// @extends .pagination__link
		cent()
		

		width 2.5em
		height @width

		// border-radius 50%
		border 1px solid color--pagination__border
		// background color1
		background color--pagination__number
		_if-hovered()

	&__hellip
		@extends .pagination__number
		background transparent
		border initial

	&__other //плейсхолдер!
		// @extends .pagination__link
		/*&:hover
			text-decoration underline*/

	&__all
		@extends .pagination__other
		_if-hovered()

	&__prev
	&__next
		@extends .pagination__other
		

	.next-arrow__head--l
		triangle(left, 2em, 2em, color--pagination__arrow)
		_if-hovered()
	.next-arrow__head--r
		triangle(right, 2em, 2em, color--pagination__arrow)
		_if-hovered()

	/*.next-arrow__body
		background color2
		width .5em
		height .4em*/

	.is-selected
		// background #BFB2A7
		background color--pagination__selected
		color white

.back-forward
	fb(j: center, a: center)
	// margin 2vw auto
	font-size em(14)

	&__prev
	&__next
	&__back-to
		// b()
		padding .2em
		margin 1vw
		text-align center
		&:hover
			text-decoration underline
	&__prev-arrow
	&__next-arrow
		font(ff: Arial)

.album
	&__images
		margin-top 3vw
	.cell
		// display inline-block
		float left
	img
		display block
		width 100%
