//СТРУКТУРА

	// МИКСИНЫ ДЛЯ ПРИМЕНЕНИЯ НА РОДИТЕЛЬСКОМ КОНТЕЙНЕРЕ:
			// grid()
				// 1 элемент фиксирован flex: none, другой flex-auto + justify - space-between
					// можно добавить спейсинг (как минимальный для подстраховки)
		//GRID
			//варианты: stack / grid; одинаковая ширина (задать % для & > *, без wrap)
			
			   
			/*app(props)
				props = arguments if length(arguments) > 1
				//переводит arguments в массив
				for prop in props
					{prop[0]} prop[1]
			p(app(one 1, two 2, three 3))
			list = (one 1) (two 2) (three 3)
			p(app(list)) */  

			grid(m = null, d = null, f = flex, w = null, j = null, a = null, ac = null, c = false, l = false, g = null, margin = .1em .5em)

				unless block is defined
					// m = row unless m
					display f

				unless j
					j = space-between unless g
					//если не передан j или g
					//если задан гаттер, justify-content = flex-start (по умолчанию)

				flex-direction d if d

				flex-wrap w if w

				justify-content j unless g

				align-items a if a
				
				align-content ac if ac

				if g 
					unless m is 'equal'
						if l 
							p(g)
						//если mode = even, g обрабатывается в сабмиксине
						//gutter
						//имеет смысл если не ставим фиксированную ширину контейнера, и колонки тоже плавающей ширины
						//gutter бывает нужен также для 'auto-space' лэйаута (space-between например), чтобы при сжатии контейнера
						// гаттер оставался (по сути типа как min-margin)
						space-items(g[0], g[1])
						// может быть попробовать этот метод передачи аргументов: http://stylus-lang.com/docs/iteration.html#mixins (не получилось, там наоборот аргументы превращаются в массив)
						// или попробовать использовать хэш
				if c
					//центрируем родительский контейнер
					margin-left auto
					margin-right auto
					width 100%
					// 	max-width
				
				// круглыми скобками аргумент по умолчанию, квадратный - то что бывает необходимость опционально изменить передачей аругемента в основной миксин
				// row(j:[space-between/space-around/center...],[w: (wrap)/nowrap])
				// дефолт, flex, space-between, stretch, wrap
				
				// gutter([j:(flex-start)/flex-end] g: ...)   
				// тоже как бы дефолт, если передаём g, flex-start. Правда может потребоваться в нек. случаях flex-end..., но мы его можем прописать аргументом 

				stack()
					flex-direction column
					// flex-wrap nowrap
				
				section()
					flex-direction column
					// flex-wrap nowrap
					// align-items center
					// будем использовать для модулей типа заголовок + основной контент, что собственно и является секцией по html5
					// контент под заголовком часто должен быть центрирован
					// но если контент должен быть 100% ширины, + если это каталог, для итемов которого задана ширина в процентах, центрировать нельзя,иначе всё сожмётся.
						
					// одновременно делает итемы типа инлайн-блоками (ширина определяется по контенту)
					// использовать как отдельный миксин section?
				
				//ПЕРВЫЙ ВАРИАНТ ПЕРЕДАЧИ АРГУМЕНТОВ В САБМИКСИН - требует вызова {m[0]}(m[1] m[2]) if m 
				equal(items = 2, gutter = 2)
					// flex-wrap nowrap
					// пока только горизонтальный
					evenWidth = (100% - (items - 1)*gutter) / items
					& > *
						width evenWidth
					
					// вызывать как grid(m: equal 6 0)
						
				//ВТОРОЙ ВАРИАНТ ПЕРЕДАЧИ АРГУМЕНТОВ В САБМИКСИН - требует указания аругемента в глобальном миксине
				wrap-centered() 
					// margin - использует глобальный аргумент
					justify-content space-around
					& > *
						margin margin
						
					// проблема: 2 элемента в гаттер-гриде, которые на мобиле должны смещаться друг под друга
					// можно использовать media query чтобы в момент смещения включать stack и корректировать марджины
					// но это неудобно, так как при любом изменении лэйаута точку смещения придётся корректировать
					
					// можно использовать js на элементе для отслеживания overflow и задавать stack, но это тоже не лучший выход по идее
					
					// самый простой вариант использовать flex-wrap в комбинации с justify: space-around и заданием марджинов элементам со всех сторон
					// (потому что при space-betweeen, например, если у нас 2 элемента, они будут съезжать влево (т.к. ширина контейнера после wrap'а остаётся неизменной,а элементы не центрируются)
					// та же проблема, что при вёрстке каталогов.
					// Т.е. делаем так:
					// grid(j: space-around)
					//	& > div
					//		margin .1em .5em
					
					//проблема: при wrap'е свободное место справа не схлопывается
					//при переходе к одной колонке тоже почему-то не схлопывается
					//именно по этой причине wrap плохое решение для таких вещей, учитывая что он также хреново работает в IE11
					//лучше на мк сменить направление флекса

				{m[0]}(m[1] m[2]) if m 
				//тут надо учесть, что при таком способе вызова миксина, если аргументы НЕ передать, миксин будет вызван с undefined, т.е. дефолтный марджин не будет вызван. 
				//Т.о при вызове не через блок надо всегда указывать аргументы
				// но двойной аргумент типа margin: vert hor таким образом не передать
				{block} if block is defined
				
				/*ошибка Maximum call stack size exceeded связана с тем, что миксин с таким именем уже существует (это может быть встроеная функция, например, even*/
	
			flex-grid()
				grid(j: space-between)

			gutter-grid(g)
				grid(j: flex-start, g: g)

			/*stack(g = null)
				grid(d: column, w: nowrap, g: g)
				// элементы автоматически растянутся вширь, ибо стретч. но можно задать even*/

			wrap-stack()
				grid(w: wrap, even: 100%)
				// стэк образуется благодаря wrap. Решает проблему с растягиванием по вертикали видео, например
				
				// вертикальный wrap! надо зафиксировать высоту контейнера, чтобы wrap сработал, т.е. добавить аргументом height
			height-wrap()
				grid(d: column, w: wrap, even: 50%)
				height 3em

			/*even-grid(items, gutter)
				even = (100% - (items - 1)*gutter) / items
				grid(even: even)*/

			//justify и align во всех вариантах выше настраивается по вкусу

			//минимальным марджином при сжатии сетки может работать паддинг на блоке (если не нужна граница)
			//а вообще по-хорошему только циклы и медиа квери спасут. Или просто ставить гаттеры всегда и хрен с ним


			/*my-cell(a = null, w = 100%)
				if a
					align-self a
				// min-width 0 // FF adjustment fo responsive images  - надо ли??
				width w
				//по дефолту делаем 100% ширину. в случае с флекс-wrap это приведёт к стеку*/

				/*cell(i = 1, cols = columns, align = '', g = gutter)
				    flex-basis: s('calc(100% * %s - %s)', i / cols, rem-calc(g))
				    min-width 0 // FF adjustment fo rresponsive images
				    if g is not 0
				        margin: rem-calc(g / 2)*/
			/*	cell-offset(i = 1, cols = columns, g = gutter)
				    margin-left: s('calc(100% * %s + (%s / 2))', i / cols, rem-calc(g)) !important*/ // 100%  на кол-во колонок + полгаттера?


			space-items(space, side = 'right', sel = false, padding = false, log = null, cycle = null, type = 'child', dividers = false)
				//TO DO разрешить использовать sel с bottom / top

				gutter = padding ? 'padding': 'margin'
				sel = sel ? sel : '*'

				if log
					p(cycle)

				if cycle
					count = 'nth'
					if cycle
						count = 'nth'
						if side == 'left' || side == 'top'
							cycle = ('(' + cycle + 'n + 1)')
						else if side == 'right' || side == 'bottom'
							cycle = ('(' + cycle + 'n)')
					else
						if side == 'left' || side == 'top'
							count = 'first'
						else if side == 'right' || side == 'bottom'
							count = 'last'

				else
					if side == 'left' || side == 'top'
						count = 'first'
					else if side == 'right' || side == 'bottom'
						count = 'last'

				if log
					p(gutter, sel, count, cycle, count, side)

				// if dividers
				// 	space = space/2
				//  type = 'of-type'
				// 	// добавляем марджин с противоположной стороны, соответствие задаём двумя массивами
				// 	sides = left right top bottom
				// 	altside = right left bottom top
				// 	for s, i in sides
				// 		if side == s
				// 			&>{sel}:not(:first-{type}{cycle})
				// 				{gutter}-{side} space

				// 			&>{sel}:not(:last-{type}{cycle})
				// 				{gutter}-{altside[i]} space
				// else

				&>{sel}:not(:{count}-{type}{cycle})
					{gutter}-{side} space

			columns(c = null, g = null, cc = null, cw = null, cs = null)
				-webkit-perspective 1
				// см. http://caniuse.com/#feat=multicolumn
				if c
					-moz-columns c
					-webkit-columns c
					columns c
				if g
					-moz-column-gap g
					-webkit-column-gap g
					column-gap g
				if cw
					-webkit-column-width cw
					column-width cw
				if cc
					-moz-column-count cc
					-webkit-column-count cc
					column-count cc
				if cs
					-webkit-column-span cs
					column-span cs

				//если использовать columns для галереи с заданием column-width,
				// проблема может быть в том, что мы не управляем непосредственно шириной колонки/итема
				// т.е.ширина будет слегка адаптивно колебаться и не всегда будет в строгой пропорции со шрифтом

				// недостаток, например: не растягивает элементы по высоте, всё равно для этого нужен flex - stretch
				//плюс к этому, используя флекс, можно проще перейти в стэк-лэйауту

				// при этом не стоит использовать адаптивный размер шрифта в галерее, т.к. адаптивность обеспечивается кол-вом колонок

				// column-span

				// column-rule-style
				// column-rule-width
				// column-rule-color
				// column-rule

				// Using both column-count and column-width is recommended to create a flexible multi-column layout. The column-count will act as the maximum number of columns, while the column-width will dictate the minimum width for each column. By pulling these properties together, the multi-column layout will automatically break down into a single column at narrow browser widths without the need of media queries or other rules.

				// Для упрощения записи есть такое короткое свойство columns, реагирующее на формат указываемых данных:

				// columns: 12em;      /* column-width: 12em; column-count: auto; */
				// columns: 2;         /* column-width: auto; column-count: 2; */
				// columns: auto;      /* column-width: auto; column-count: auto; */
				// columns: auto 12em;  column-width: 12em; column-count: auto;
				// columns: 2 auto;    /* column-width: auto; column-count: 2; */
				// Что будет, если указать и количество колонок, и оптимальную ширину? Согласно спецификации, в этом случае column-count определяет максимальное количество колонок:

				// article {
				//     columns: 150px 3;  /* column-width: 150px; column-count: 3; */

				// при критическом уменьшении шрифта можно переходить к column-width (держит фиксированную ширину колонки, но соответственно меняет их число)
			break(break = avoid)
				-webkit-column-break-inside break
				          page-break-inside break
				               break-inside break
				//NB: в мобильном Хроме почему-то судя по всему нижние марджины могут переноситься в след. колонку

		// EDIT/DEBUG
			edit(color = black, important = false)
				//ported from jeet
				/**
				 * View the grid and its layers for easy debugging.
				 * @param {string} [color=black] - The background tint applied.
				 * @param {boolean} [important=false] - Whether to apply the style as !important.
				 */
				//в axis есть что-то похожее - debug()
				if important
					*
						background rgba(color, 5%) !important
				else
					*
						background rgba(color, 5%)


	// UNITS
		get-vw(px, viewport)
			return (viewport/100) / px
		// 16px = 1rem = 1vw при ширине vp 1600

		/*ks-em(value, base = 16)
			//from kouto-swiss
			_strip-units(val)
				unit(val, "")
			value = _strip-units(value)
			base = _strip-units(base)
			value / base * 1em*/
		
		em(value, base = 16)
			_strip-units(val)
				unit(val, "")
			value = _strip-units(value)
			base = _strip-units(base)
			round(value / base * 1em, 2)
			
		// ks-percent(value, total)
		// 	//from kouto-swiss
		// 	_strip-units(val)
		// 		unit(val, "")
		//     value = _strip-units(value)
		//     total = _strip-units(total)
		//     value / total * 100%
		
		pr(value, total)
			round(value / total * 100%, 1)
			// есть также встроенная в stylus функция percentage http://stylus-lang.com/docs/bifs.html#percentagenum
		   
		// ks-rem-base = 16px
		// ks-rem(values)
		// 	base = ks-rem-base is a "unit" ? ks-rem-base : 16
		// 	result = null
		// 	for value in values
		// 		if type( value ) == "unit" && ( unit( value ) == "px" || unit( value ) == "" )
		// 	  		value = unit( round( value / base, 3 ), "rem" )
		// 	  		if value == "0rem" { value = 0 }
		// 	  result = result != null ? result value : value
		// 	result
		      
		   /*Usage

			   div
			       font-size: rem( 12px )
			       margin: rem( 10px auto 20 )

			   ks-rem-base = 20px

			   div
			       font-size: rem( 16 )
			       margin: rem( 10 auto 20px )

			   Result

			   div {
			     font-size: 0.75rem;
			     margin: 0.625rem auto 1.25rem;
			   }

			   div {
			     font-size: 0.8rem;
			     margin: 0.5rem auto 1rem;
			   }
			*/
				
	// RESPONSIVE QUERIES AND IMAGES
			
		fsdMQ(query)
			bp1em = em(query);
			if @font-size 
				bp = bp1em * @font-size
			else 
				bp = bp1em
			return bp
			
			// генерирует для элемента media query в em,которая рассчитывается с учётом font-size, выставленного для данного элемента
			// пример вызова:
			//.element
			// 	font-size 1.5em
			// 	+below(fsdMQ(900px))
			// прикол в том, что при изменении размера шрифта медиа квери автоматически сместится и т.о. если мы используем для переключения стэкового лэйаута, точка его включения скорректируется автоматически
		
		adapt-pic(url, resolutions = 480 1024 1920)
			dir = dirname(url)
			ext = extname(url)
			base = basename(url, ext)
			// p(dir, ext, base)
			+below(unit(resolutions[0], px))
				path = pathjoin(dir, base + '-' + resolutions[0] + ext)
				background-image url(path);
			for res, i in resolutions
				if resolutions[i+1]
					+between(unit(resolutions[i], px), unit(resolutions[i+1], px))
						path = pathjoin(dir, base + '-' + resolutions[i+1] + ext)
						background-image url(path);
				else
					+above(unit(resolutions[i], px))
						path = pathjoin(dir, base + '-' + resolutions[i] + ext)
						background-image url(path);
						
			// usage:  adapt-pic(img + 'background-image.jpg')
			// usage:  adapt-pic(background-image.jpg') //усовершенствованная версия, префикс пути добавляется в миксине
			// генерирует блок медиа-квери с background-image-url в формате gulp-responsive-config/gulp-responsive
				
	// FLEX-BOX
		//вызов флекса с дефолтными параметрами
		fb(f = flex, d = null, j = null, a = null, w = null, ac = null)
			// если использовать с дефолтами, отличными от null, каждый вызов миксина будет записывать дефолтное значение для свойства
			// что нужно учитывать, если используем в mediaQuery, например ("неявно" прописанное свойство будет оверрайдить прописанное вне медиаквери)
			if f
				display f
			if d
				flex-direction d
			if j // system default = flex-start
				justify-content j
			if a // system default = stretch
				align-items a
			if w // system default = nowrap
				flex-wrap w
			if ac
				align-content ac

			//jeet, центровка элемента
			// cent(max-width = 1440px, pad = 0)
			// 	// cf() // clear-fix
			// 	width: auto
			// 	max-width: max-width
			// 	float: none
			// 	display: unquote('block')
			// 	margin-right: auto
			// 	margin-left: auto
			// 	padding-left: pad
			// 	padding-right: pad

	// ALIGNMENT
		// 3 способа центрирования: margin-auto, transform , flex (margin-auto - на вложенном элементе, поэтому через & > *)
		//структура, когда вложенный контейнер центрирован по центру родительского и содержит текст, выровненный по своей левой стороне
		auto-margin()
			margin-left auto
			margin-right auto
		
		shell()
			width 100%
			cent()
			// а вот это необязательно:
			// & > div
			// 	text-align left
			// 	// margin 0 auto


		stretch-items(method = 'flex')
			if method is 'flex'
				display flex
			//растянуть элементы в контейнере до 100% высоты родителя. Работает, даже если для родителя высота не задана явно (в отличие от height=100% в Хрома)
			//но не для картинок img с height auto
		

		cent(m = 'flex', d = both)
			
			jeet-align()
				/**
					* Center an element on either or both axes.
					* @requires A parent container with relative positioning.
					* @param {string} [direction=both] - Specify which axes to center the element on.
					*/
				// похожее есть в axis - vertically-align, но через position: relative
				// по сути включает self-align
				position absolute
				// transform-style preserve-3d - приводит к искажению в Firefox
			
				if (d == hor)
					left 50%
					transform translateX(-50%)
				else if (d == ver)
					top 50%
					transform translateY(-50%)
				else
					top 50%
					left 50%
					transform translate(-50%, -50%)
			
			self-align()
				// вносим только поправку на размер элемента, не указывая абсолютное позиционирование
				// transform-style preserve-3d - приводит к искажению в Firefox
					
				if (d == hor)
					transform translateX(-50%)
				if (d == ver)
					transform translateY(-50%)
				if (d == both)
					transform translate(-50%, -50%)


			flex-align()
				display flex
				if (d == both) or (d == hor)
					justify-content center
				if (d == both) or (d == ver)
					align-items center

			if m is 'flex'
				flex-align()
			if m is 'translate'
				jeet-align()
			if m is 'self'
				self-align()	


		

	// RATIO-BOX

		ratio-box(r = null, w = null, m = null, sel = null, pseudo = true, hang = true, l = false)
			width = w ? w : 100%
			sel = sel ? sel : '*'
			ratio = r ? r : 1
			
			//не работает на элементах, которые сами являются флекс-контейнерами, но это в общем-то и не нужно, и можно сделать overlay-элемент

			if l
				p(width)

			// INITS
			unless block is defined
				m = 'default' unless m
				
				// +cache('initConst' + w)
				position relative
				if l
					p(w)
				width w if w
						
				if pseudo
					//спасает, когда нужен рэтио-бокс на флекс-итеме(т.е. элементе, который находится во флекс-контейнере) (т.к. проценты считаются от высоты контейнера, бокс схлопывается)
					//срабатывает в Хроме, но что-то не работает в FF и Edge
					//но иногда псевдоэлементы нужны для других целей, тогда во флекс-боксе лучше создать дополнительный див-обёртку; ratio-box повесить на внутренний элемент
					//под псевдоэлементом не работают ссылки
					if l
						p(width/ratio)
						
					&:before
						height 0
						padding-bottom (width/ratio)
						content ''
						display block
						
				else
					height 0
					padding-bottom (width/ratio)

						
				// +cache('initVars' +)
				
			// PRESETS
			default()
				if hang
					& > {sel}
						pos(absolute)
				//если содержимое рэтио-бокса верстается на абсолютах. размер и позиционирование будет задаваться для каждого блока отдельно	
			stretch()
				//если внутренний контейнер растягивается и можно верстать как обычно (для картинок, видео)
				& > {sel}
					pos(absolute, t: 0%, l: 0%)
					size(100%)
			
			// для видео - передать селектор iframe и рэтио 16/9
					

			/*video()
				ratio = r ? r : 56.25%
				sel = sel ? sel : 'iframe'*/
				
			{m}() if m
			{block} if block is defined
			
		overlay()
			pos(absolute, 0, 0)
			size(100%)
			
		contained()
			max-width 100%
			max-height 100%
		/*ks-ratio-box(ratio = 1/1)
			ratio = _strip-units( ratio )

			overflow: hidden
			position: relative

			// The container's height, as a percentage of the container's width, is set by assigning  padding-top to a pseudo-element.
			&:before
				content: ""
				display: block
				height: 0
				padding-top: (1 / ratio) * 100%*/

// МИКСИНЫ ДЛЯ ПРИМЕНЕНИЯ НА ЭЛЕМЕНТЕ

	bg(bcl = null, bi = null, bs = null, bp = null, bo = null, br = no-repeat, ba = null, bc= null)
		// background //color image position/size repeat origin clip attachment
		// обычным шотхэндом background не всегда удобно пользоваться, например, если какое-то свойство задаётся отдельно помимо background, оно полностью переписывает background, а не только себя
		background-image bi if bi 
		background-size bs if bs
		background-position bp if bp
		background-color bcl if bcl
		background-origin bo if bo
		background-repeat br if br
		background-clip bc if bc
		background-attachment ba if ba
		

	// FLEX-ITEM
		fi(o = null, as = null, f = null, g = null, s = null, b = null)
			//flex-item
			if o
				order o
			if as
				align-self as
			unless f is null// = flex-grow flex-shrink flex-basis, default = 0 1 auto  // initial, auto, none, <positive number>
				flex f
			unless g is null// system default = 0
				flex-grow g
			unless s is null// system default = 1
				flex-shrink s
			unless b is null// system default = auto
				flex-basis b

				// flex: initial
				// 	Equivalent to flex: 0 1 auto. (This is the initial value.) Sizes the item based on the width/height properties. (If the item’s main size property computes to auto, this will size the flex item based on its contents.) Makes the flex item inflexible when there is positive free space, but allows it to shrink to its minimum size when there is insufficient space. The alignment abilities or auto margins can be used to align flex items along the main axis.

				// flex: auto
				// 	Equivalent to flex: 1 1 auto. Sizes the item based on the width/height properties, but makes them fully flexible, so that they absorb any free space along the main axis. If all items are either flex: auto, flex: initial, or flex: none, any positive free space after the items have been sized will be distributed evenly to the items with flex: auto.

				// flex: none
				// 	Equivalent to flex: 0 0 auto. This value sizes the item according to the width/height properties, but makes the flex item fully inflexible. This is similar to initial, except that flex items are not allowed to shrink, even in overflow situations.

				// flex: <positive-number>
				// 	Equivalent to flex: <positive-number> 1 0%. Makes the flex item flexible and sets the flex basis to zero, resulting in an item that receives the specified proportion of the free space in the flex container. If all items in the flex container use this pattern, their sizes will be proportional to the specified flex factor.
				// By default, flex items won’t shrink below their minimum content size (the length of the longest word or fixed-size element). To change this, set the min-width or min-height property.

				//Важно! эти значения работают только если прямо не задана например width в %, тогда флекс будет следовать этому значению независимо от overflow (что логично)
				// а вот если задать width в еm одновременно с flex none, контейнер не будет сжиматься меньше чем это значение
	// FONT
		font(cl = null, fs = null, ff = null, ta = null, tt = null, td = null, ls = null, ws = null, fw = null, wh = null, lh = null, st = null, ti = null, fv = null, ts = null)
			if cl
				color cl
			if fs
				font-size fs
			if ff
				font-family ff
			if ta
				text-align ta
			if tt
				text-transform tt
			if td
				text-decoration td
			if ls
				letter-spacing ls
			if ws
				word-spacing ws
			if fw
				font-weight fw
			if wh
				white-space wh
			if lh
				line-height lh
			if st
				font-style st
			if ti
				text-indent ti
			if fv
				font-variant fv
			if ts
				text-shadow ts

	// POSITION
		pos(p = null, t = null, l = null, b = null, r = null, z = null)
			if p
				position p
			if t or t == 0
				top t
			if l or l == 0
				left l
			if b or b == 0
				bottom b
			if r or r == 0
				right r
			if z
				z-index z
		
		align-axis()
			transform translate(-50%, -50%)

	// BORDER
		b(color = black)
			border 1px solid color

	// SIZE
		/*ks-size(values... )
			if length( values ) == 1
					width values[ 0 ] || auto
					height values[ 0 ] || auto
			else
					width values[ 0 ] if values[ 0 ] != false
					height values[ 1 ] if values[ 1 ] != false*/

		size(values...)	
			if length(values) == 0
				width 100%
				height 100%
			if length( values ) == 1
				width values[ 0 ] || auto
				height values[ 0 ] || auto
			else
				width values[ 0 ] if values[ 0 ] != false
				height values[ 1 ] if values[ 1 ] != false

	// CLEARFIX
		ks-clearfix()
			//в jeet то же самое
			/*if ks-support-ie <= 7
				 *zoom 1*/
			&:before
			&:after
					content " "
					display table
			&:after
					clear both

	// ELLIPSIS
		ellipsis()
			white-space nowrap
			text-overflow ellipsis
			overflow hidden

	// PSEUDO-MARKER 

	prepend-marker(url = null, g = 0, embed = true, content = '', l = false, w = null, h = null, bs = null)
		//w, h, bs (background-size - скейлинг картинки, особенно полезно для svg)
		
		//если у нас картинка
		if url
			content = ''
			// h ?= image-size(url)[1] 
			// w ?= image-size(url)[0]
			h = h ? h : image-size(url)[1] 
			w = w ? w : image-size(url)[0]
			
			//debug 
			if l
				p(image-size(url))
			// image-size работает также для svg width/height (но view-box пока не учитывает)

			// ПРИМЕЧАНИЕ ПО ПОВОДУ ДЕФОЛТОВ
			// ?=    это (is defined) ? :
			// null считается false, но при этом defined
			// т.е. можно или не писать дефолт arg = null и тогда можно юзать ?= для назначения дефолта, 
			// или использовать проверку на true false типа arg = (arg) ?  :
			//  http://stylus-lang.com/docs/operators.html#variable-definition-is-defined
			//  с другой стороны, undefined переменные явлвяются true, т.е. нельзя использовать такую проверку if (arg) если до этого не стоит arg = null
			// т.к.  ?= более элегантно и очевидно, лучше использовать этот вариант (возможные неопределенённые аргументы можно указать специальным комментарием к миксину)
			
			
		padding-left s('calc(%s + %s)', w, g) //расширяем элемент, чтобы маркер помещался внутри
		pos(relative)
		// выравниваем сам главный контент посередине тоже
		cent(hor: false)

		&::before
			content content
			// content по дефолту пустой ('')
			pos(absolute, t: s('calc(50% - %s)', h/2), l: 0, z: 2)
			
			// располагаем по вертикали: 50% высоты родителя с поправкой на половину высоты самого маркера (т.е. точно посередине)
			// вместо поправки s('calc(50% - %s)', h/2), можно было бы добавить transform translateY(-50%)
			// pos(absolute, t: 50%, h/2), l: 0, z: 2)
			// transform translateY(-50%)
				
			// расположение слева - отступ от элемента, тут учитываем ширину маркера (гаттер)
			height h
			width w if w
			// высота - связана с поправкой на высоту по вертикальному раположению

			if url && embed
				background embedurl(url) center no-repeat
				background-size bs if bs
			if url && !embed
				background url(url) center no-repeat
				background-size bs if bs
				
			// если у нас символ или строка, передаём как аргумент content, w, h и не передаём url.
			if content
				line-height h
			{block}
			
		// варианты вызова миксина:
		// prepend-marker(url: img + 'kyivstar.png', g: .5em)
		// prepend-marker(content: 'mark', g: 2em, w: 2em, h: 2em)
			
		// если вызываем и не url, и не контент, а псевдо-треугольник, например:
		
		//height (h), передаваемый в миксин, должен соответствовать высоте маркера, задаваемой в блоке. Т.е. вызывать например так:
		/*marker-height = 1em
		+prepend-marker(g: -1em, h: 2em)
			triangle(right, 2em, marker-height, color3)	*/
		//можно сам вызов сделать миксином с несколькими кастомными пресетами(треугольник, картинка...)
		
		/*marker-height = 1em
		marker-width = 1.65em
		+prepend-marker(w: marker-width, h: marker-height, g: .5em)
			triangle(right, marker-width, marker-height, white)*/
				
		// с svg проблема в том, что указанный в самом svg размер довольно большой, и если автоматически его использовать, маркер будет огромным.
		// следовательно надо либо прописать для него в svg width 100% height 100% и передавать в миксин нужный размер для псевдоэлемента,
		// либо скейлить с помощью background-size
		// однако background-size плохо работает с гаттером, так как тот заточен под фактическую ширину псевдоэлемента, т.е. это не вариант
		// prepend-marker(img +'envelope.svg', g: 1em, w: 2.5em, h: 2.5em)
			
	stripe-gradient(color, terminator)
		return linear-gradient(
			to right,
			color 0%,
			color terminator,
			alpha(white, 0.75) terminator,
			alpha(white, 0.75) 100%
		)
