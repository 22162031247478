// ЭТАП -1 подключаем шрифты

/*@font-face
	font-family Arimo Regular
	src url("../fonts/Arimo Regular.woff")*/

/*@font-face
	font-family Arimo Bold
	src url("../fonts/Arimo Bold.woff")*/
	
/*@font-face
	font-family Albionic Bold
	src url("../fonts/Albionic.woff")*/
	
	


// TEXT BLOCKS

// сюда втюхивать правила, которые будут стартовыми для большинства проектов
// общий стиль для всего
// ресеты:
body
	a
		color black
	
//styles reset
h1, h2, h3, h4, p
	margin 0 0 
	padding 0 0

//в общем-то марджины на заголовках, ависимые именно от тэга, нужны только внутри article, например.
// Поэтому можно добавить 
// article
// 	& h1
// 	& h2
// 	& h3
// 	& h4
//...

// ЭТАП 0.
	
// СНИМАЕМ СТИЛИ ТЕКСТА ИЗ ФОТОШОПА. Cмотрим, какие у нас вообще есть разновидности заголовков по внешнему виду и пишем для них классы-миксины
//в принципе это можно сделать вместо задания шрифтов по цифрам (путаюсь)

//написали список какие шрифты и какого размера они на макете в пойнтах.
// regular 14.85
// italic 41.67
// blaсk 29.17
// porter tiles 16.67

//mob: regular 12pt
//header 18pt
//porter-tiles - без изменений
//italic 30 и 24
//список дизайнеров и заголовок новости 13

// определяем примерное соответствие с rem. 14.85 = 1em 
emBase = fsCoef = 16// делим на него, чтобы получить размер шрифта в rem / em

// font-size для каждого миксина меряем относительно 16px = 1rem - но указываем в em. 
// В некоторых дизайнах размер может "плавать, тогда указываем дефолтный, если не указан оверрайд из лэйауте"\
// параметры, которые часто варьируются в дизайне. можно внести в миксины и задать через дефолты, чтобы можно было оверрайд делать передачей аргумента в миксин, а не отдельным вызовом font из лэйаута)
// как вариант, можно сделать всё в одном миксине и сделать свитч по кейсам

// NB перестроить, чтобы избежать дублирования кода при вызове миксина только с другим размером шрифта!

// инспектируем и записываем стили текста для всех элементов на странице, выделяем повторяющиеся (открываем charecters/paragraphs pane, она показывает стили текста при простом выборе слоя)



_f-regular(fs = 16, cl = font-color, tt = none)
	font(ff: ArialMT, fs: em(fs), cl: cl, tt: tt)

_f-regular--header(fs = 12, cl = color-0, tt = capitalize)
	font(ff: ArialMT, fs: em(fs), cl: cl, tt: tt)
// задаём для html _f-regular (стиль, который будет применяться в большинстве случаев)
html
	_f-regular()
// в остальных миксинах указываем ff, только если он отличается от указанного в html / не каскадирует
	
_f-header(fs = 22, cl = color-0, tt = uppercase)
	font(ff: ArialMT, fs: em(fs), cl: cl, tt: tt, fw: bold)
		
_f-motto(fs = 84, cl = white)
	font(ff: AnastasiaScript, fs: em(fs), cl: color-0, tt: none, fw: normal)
	// text-shadow 5px 5px 2px white

_f-breadcrumbs(cl = font-color)
	font(ff: ArialMT, fs: em(14), cl: cl)
	span
		font-family Arial
	a
		font(cl: cl)

//1 ЭТАП. 
// имея готовые классы-миксины для фонтов, определяем как они будут соотноситься с семантическими тэгами.
// возможно имеет смысл привязать класс глобально к какому0то типу заголовков или p, если большинство их них выглядят в соответствии с этим классом
// "Стартовые" общие стили для текстовых элементов. В принципе этого может быть иногда достаточно
//общие стили для всего текста

$_allText
	// font(cl: color2)

// миксины для сброса стилей к браузерному дефолту
_header-defaults()
	/*h1 h2 h3 h4 h5 h6
		display block
		font-weight bold*/
	// (всё равно это не меняем)
		
	h1
		font-size 2em
		margin: .67em 0
	h2
		font-size 1.5em
		margin .83em 0
	h3
		font-size 1.17em
		margin 1em 0
	h4
		margin 1.33em 0
	h5
		font-size 0.83em
		margin 1.67em 0
	h6
		font-size 0.67em
		margin 2.33em 0
_p-default()
	p
		margin 0.83em 0

//общие стили для заголовков // без марджинов
$_headers
	@extend $_allText
	font(ff: MyriadPro-Regular, ta: center)
	
h1, h2, h3, h4, h5, h6
	@extend $_headers

// применяем отдельные стили для заголовков
h1
	_f-header(30)
	// добавляем мквери...

h2
	_f-header(24, tt: null)
	// добавляем мквери...
h3
	_f-header(18)

h4
	_f-subheader()
	// margin-bottom .75em

// общий стиль для строчного текста	
p
	@extends $_allText
	_f-regular()

// на самом деле семантические тэги используются для смысловой разметки документа и не относятся прямо к его оформлению, т.е. все h1-h6 могут быть оформлены одинаково или по-разному
// поэтому для каждого тэга заголовка прописывается структурный класс (по роли, которую он выполняет в приложении)
// а уже для этих структурных классов задаётся представление (пищем классы-миксины типа _f-...) и присваиваем их структурным тэгам заголовков
// структурные тэги:
.h
	&--page
		_f-header()
		// заголовок страницы
	&--section
		_f-header()
		// заголовок секции, как правило, h2
	&--product
	&--post-short
	&--brand
	&--aside
	&--add-comment
		//
	// заголовок - название продукта, как правило, h1 или h2
//учитывать надо, что некоторые тексты добавляться будут из админки и не нести структурных классов, поэтому некоторые дефолты надо прописать и для семантических тэгов h1-h6 (стили для структурных классов всё равно их перепишут, т.к. будут более специфичными)
//такого типа подход может сочетаться с наименованием по БЭМу (элемент может получить второй структурный БЭМ-класс по имени модуля, в котором находится).
// т.е. есть первый структурный класс (по функции, обычно объединяет элементы, выполняющие сходную функцию на разных страницах) и второй - по БЭМу

//например, section.section.feedback содержит заголовок .feedback__h.h--section и .feedback__main
// суть: section - семантический тэг, к нему не привязываем внешний вид; класс .section - выделяет в тип объектов .section, для которых характерна определённая структура (задаём её миксином grid(stack) с гаттером ()) .feedback - характеризует смысловое содержание
// feedback__h и feedback__main - характеризуют сабмодули как дочерние по отношению к модулю .feedback
// для краткости можно обойтись без feedback__h и получать заголовок через .feedback .h--section
//h--section - выделяет тип объектов, для которых характерно общее представление (заголовок секции)

//feedback__main содержит тэг form, при задании класса для него думаем, какую специфическую функцию он несёт по отоншнию к другим формам, которая может влиять на его офрмление.
//назвав его form--feedback, мы не особо выиграем, т.к. это слишком специфично (хотя можно будет экстендами создать общий класс с form--user, например, но зачем лишний код, если формы по сути не будут отличаться)
//а вот назвав его form--textFields, мы даём подсказку о его содержании, которое как раз может повлять на оформление (бывают форму и без текстовых полей)

// для классов типа .h--section, h--product, если они вдруг окажутся слишком специфичны, можно экстендить плейсхолдеры, создавая родительский класс и задавая общие свойства для них
// собственно, со страницами так и делаем (хотя стоило бы переименовать #p-... в #p--)
// БЭМ-классы тоже можно экстендить: 
// .button
// 	.parent_&
// 		@extends...
// 	.another-parent_&
// 		@extends...

//такие классы типа something-- можно лешко найти в html поиском и посмотреть, какие разновидности бывают. Хотя в принципе и по БэМу: _something


// заголовок страницы - за пределами main, т.к. помимо main могут быть другие блоки (которые не main) а заголоок страниц в равной мере относится ко всем

// ЭТАП2. Для каждой из страницы потом эти стили можно оверрайдить при необходимости, указывая имя модуля или блока

//например, на странице продукта название товара (семантически это h1) не должен быть таким большим, можем его уменьшить
/*#p-product h1
	margin 0 0
	font-size 1.8rem*/

// НАСТРОЙКА ТЕКСТОВЫХ МОДУЛЕЙ			
$_text-module
	_header-defaults()
	_p-default()
	b(transparent)
	margin textBlock-marginVert auto // предпочтительно margin, т.к. из-за эффекта схлопывания соседних marginов не будет создаваться двойной отступ между элементами
	max-width allTextModulesMaxWidth !important// тут задать максимальную ширину текстовых блоков

	a
		display inline
		font(td: underline, cl: font-color)
				
// определяем. какие элементы на страницы у нас будут считаться текстовыми, для которых задействиует сброс марджинов к дефолту
.extHTML
$_description
$_text-blocks
	@extend $_text-module
	
$_text-blocks
	// @extend $_has-contrast

.post-short
.comment
.text
article
	@extend $_text-blocks
	

_framed()
	&__shell
		b(color-0)
		padding 3em 2vw
/*_$_framed
	&__shell
		b(color-0)
		padding 3em 2vw*/

$_catalog-description
	// auto-margin()
	// @extends _$_framed
	_framed()
	// max-width 1300px

/*$_shell-description
	.description
		.l-core
			grid(stack, g: 2rem bottom)
		&__main
			font(ta: center)
		b(color-0)

		padding 3em 2vw
*/

.description--page // $page->body
	// &__main // именно main, т.к. если у нас core-лэйаут, ограничение по ширине должно относиться только к главному центральному элементу
	@extends $_description

	#p-product &
	#p-products &
		// @extends $_has-contrast
	
	// для специфических description'ов пишет оверрайды вложенными селекторами где надо группируем в экстенды:

	#p-product & //$product->body
		@extends $_catalog-description
		
	#p-products & //$category->description
		@extends $_catalog-description
		// max-width categoryDescriptionMaxWidth
	
	#p-main &
		_framed()
		// @extends _$_framed
	
