// RESETS

// li-reset()
	

a-reset(display = block) 
	// не использовать, если ссылка должна быть inline (допустим, перед ней нужен маркер на li в той же строчке	
	display display // необходимо, чтобы ссылка занимала весь блок, а не только контент (текст). 	// не использовать, если ссылка должна быть inline (допустим, перед ней нужен маркер на li в той же строчке	
	// color color
	text-decoration none	
ul-reset()
	// list-style-position inside
	// list-style-type none
	list-style none inside
	margin 0 0
	padding 0 0
pre-reset()
	padding 0
	margin 0
	// overflow default		


// BASE RULES

// RESETS
html 
	box-sizing border-box
	overflow-y scroll // фикс, чтобы vw не учитывал скроллбар: http://stackoverflow.com/questions/19667987/how-to-prevent-vh-units-from-ignoring-the-scrollbar
*,
*::before,
*::after
	box-sizing inherit
	
html
body
span
	margin 0 0
	padding 0 0
a
	a-reset()
ul
ol
	ul-reset()
/*li
	li-reset()*/
pre
	pre-reset()
img
video
	display block
iframe
	border 0 none
form
	margin 0
/*input 
	border 0 none // работает также в IE*/
// - надо применять локально, иначе полетят стили корзины	
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}
