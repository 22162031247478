// СТРУКТУРА МИКСИНА

test(m = null, commonVar = 0)
	
	// http://stylus-lang.com/docs/introspection.html! попробовать это


	// init (common) properties. 
	// Их иногда имеет смысл кэшировать:
	// кэширование статичного рулсета: любой класс, получающий такой же рулсет, экстендит предыдущие, 
	// есть смысл, если рулсет большой, а классов меньше, тогда будет типа
	// .a
	// .b
	// {BIG rulset}
	// если же это всего несколько правил, тогда наоборот имеет смысл не дублировать селекторы, т.к. они займут больше строк, чем дублирующиеся рулсеты
	// в первом случае кэшируем рулсет просто под каким-то именем
	
	// во втором случае кэшируется свойство со значением, т.е. чтобы класс экстендил, должно совпасть и свойство, и значение, с которым он вызван
	// его надо кэшировать в отдельную кэш-переменную, т.к. иначе если значение у другого класса будет другим, пропишется всё равно старое из кэша
	// если переменную не добавить к кэшу, то она вообще не пропишется
	// правильно:
	// +cache('commonVarProp' + commonVar)
	// 	commonVarProp commonVar
	// неправильно:
	// +cache('commonVarProp')
	// 	commonVarProp commonVar
		
	// каждая кэш-функция создаёт свою группу селекторов по принципу экстенда
	// p(block)
	// p(block is defined)
	
	
	
	// в stylus undefined тоже = true, поэтому чтобы проверить, передан ли аргумент, надо использовать is defined
	unless block is defined
		m = default unless m
		// чтобы дефолт не прописался, надо передать m: none или любое другое значение (при этом m будет = true, но ничего не запишется, т.к. нет такого миксина 'none') но не false, т.к. тогда дефолт сработает
				
		+cache('inits')
			commonConst initial
			commonConst2 initial

		+cache('commonVarProp' + commonVar)
			commonVarProp commonVar
		
	//presets

	default(a = 1)
		presetConstProp default
		presetVarPropA a
		
	vertical(b = 2)
		presetConstProp vertical
		presetVarPropB b
	
	// задание пресета через аргумент. Можно вызывать как test() или test(vertical), будут записаны init + preset.
	// обозначаем пресет m, т.к. p() в стайлусе это встроенная функция логгирования
	{m}() if m //если передаётся m, вызывается подмиксин-пресет с именем m
	//Как бы всё понятно, вызывается инит-рулсет + дефолтный пресет (если прописан m = default) или же надо передать m: (preset)
	//но очевидно, со своими кастомными аргументами вызывать таким образом субмиксин не получится, т.к. аргументы никак не передашь. 
	// Так что если надо инициализировать с кастомными аргументами, вызывать как блок-миксин или передавать аргументы через глобальный миксин
	// допустим так тогда: grid(even, items:4, g: 2) при этом items булет использоваться только модом even
	// учесть, что при вызове через блок-миксин у нас сейчас пропускается инициализация
	
	if block is defined // иначе Rapture глючит при вызове микснов с {block} обычным способом (без +)
		{block}	// а таким образом можно вызвать любой пресет, но, при этом вызывается и основной миксин (т.е. и init-рулсет). 
	// Т.о для инит-рулсета надо прописать, что он не должен вызываться если определена переменная block
	// так же при этом условии назначаем дефолтный пресет;
	
	// дефолтный m задаём равынм null (m = null) чтобы при вызове блок-миксина дефолт не вызывался

	// если нужно только ИЗМЕНИТЬ пресет (например в mq), тогда: 
	// +test
	//    vertical()

	//test3(m = null, a = null, b = null)
	//если задать дефолты для аргументов пресетов в главном миксине, то можно будет их менять при выборе пресета аргументом
	//но вообще чтобы избажать путаницы и иметь возможность разделить аргументы и дефолты лучше наверно использовать блок-миксин, а в главном прописывать только для const-свойств
	
	// Итого, такая запись инициализирует модуль с дефолтным пресетом, по медиа-квери переключает в vertical -mode
	// .module-B
	// 	test()
	// 	@media smin.fablet
	// 		+test()
	// 			vertical()
	// чтобы совсем избавиться от дублирования, можно сделать так: модуль создаётся без пресет-рулсета, он дописывается в зависимости от media-query
	// .module-B
	// 	test(m: none)
	// 	@media smin.fablet
	// 		+test()
	// 			default()
	// 	@media smax.mobile
	// 		+test()
	// 			vertical()		
	
	// топорный метод - ручной оверрайд, или оверрайд отдельных свойств блок-миксином
	// каскадингом переписать любое свойство, если нужно

arrowed(width = 15em, vert-hover = 10%, arrWidth = 3em)
	// ARROWS
	
	arrWidth = arrWidth
	vert = 0%
	vert-hover = vert-hover
	hor = width/2 - arrWidth/2
	
	&:hover 
		&::before
		&::after
			opacity 1
		&::after
			bottom vert-hover
		&::before
			top vert-hover
			
	&::before
	&::after
		// @extend $_pseudo-arrows
		transition all 1s
		content ''
		display block
		opacity 0
		
	&::before
		pos(absolute, t: vert, l: hor)
		arrow-slim(cl: white, d: down, w: arrWidth)
	&::after
		pos(absolute, b: vert, l: hor)
		arrow-slim(cl: white, d: up, w: arrWidth)

// устарели и не нужны:
	product(width, marginHor = null, marginRight = null, color1 = white, color2 = black, imgHeight = false, infoHeightRatio = 1/4, maxWidth = null, description = true)
		// marginHor = null, т.к. предполагается, что блоки продуктов будут размещаться с помощью флекса space-between, который беспечит расстояние между ними
		.item
			b()
			// fb(column) // можно обойтись, т.к. все равно картинка вверху
			width width
			/*if maxWidth
				max-width maxWidth
			margin (width / 16) marginHor
			if marginRight
				margin-right marginRight*/

			// картинка с изображением товара:
			&__image
				if maxWidth
					max-width maxWidth
					/*if square
						max-height maxWidth */
				width width
				if imgHeight // проблема клиента предоставить квадратные картинки; иначе они будут урезаться
					height imgHeight

			// весь блок с информацией
			if description
				// mixin для быстрой смены ориентации компонентов
				align(d)
					if d == 'row'
						width 100%
						fb(j:space-between)
					if d == 'column'
						height 100%
						fb(d:column, j:space-between)

				// общие параметры шрифта
				font(cl: color1, fs: (width / 16))
				background transparent // пространство между картинкой и описанием

				// сброс цвета шрифта для ссылок:
				a
					color color1

				&__info
					width 100% // в любом случае надо, чтобы растянуть по ширине модуля
					align(column)
					height (width * infoHeightRatio)
					padding (width / 32)
					background color2

					margin-top .5vw

				// блок с названием и описанием товара
				&__description
					// padding (width / 16)
					align(row)
					text-align left
					& > div
						margin .3em
					// margin (width / 32)

				&__name
					font(fs: 1em, fw: initial)
					margin .2em .2em .2em 0

				// блок с ценой и кнопкой добавления в корзину
				&__cart
					align(row)
					font(fs:1.05em)
					a
						center-items(flex)
						img
							width (width / 8)
				&__price
					fb()
					span
						margin 0 .3em
						font(fs: 1.1em, fw: bold)

				&__variant
					color color1
					padding 0 0
					fb(d: column, a: flex-end)
					select
						color color1
						font-size .8em
						border 1px solid color1
						margin 1px 0
						width 100%
						background transparent

				&__to-cart
					width (width / 7)
					height @width
					center-items(flex)
					// border .2em outset white
					padding .1em

					input
						width 100%
						padding 0 0 // обнуляем браузерный стиль, хотя в принципе необязательно, т.к какртинка бэкграундом
						border 0 none
						cursor pointer
						background url(img + '/cart_white.png') center/contain no-repeat

					// &-sum
					// &-currency
					// 	fb(justify: center)
	product-overrides(margin = 1vw 2%)

		.item
			pos(relative)
			// margin margin	//вертик. марджин в % не работает, т.к. не задана height родит. контейнера (всей галереи)

			shadow(alpha(color2, .6))
			border 1px solid color2

			vertMargin = 1vw
			margin-top vertMargin
			margin-bottom vertMargin


			& > a
				ratio-box(imageAspectRatio * 100%, preset: 'image')
				// overflow hidden // можем обрезать низ фотографии:
				// object-fit contain // не подходит для абсолютно позиционированных элементов?


			&__name
				width 100%
				height 12%
				pos(absolute, b: .5%)

				background-color alpha(color4, 0.8)
				b(color2)

				transition all .5s ease-in-out;
				// transition-delay .2s

				& > a
					cent()
					height 100%

					color color1
					font-size 28px
					text-shadow 1px -1px 3px color2
					@media screen and (min-width: 800px) and (max-width: 1365px)
						font-size 2vw


			&:hover .item__name
				transform scale(1.05)

			// В product-tag:
			/*&__info
				border 1px solid color2
				background-color rgba(red(color4), green(color4), blue(color4), 0.8)
				cent()
				pos(absolute, b: 5px)

			&__description
				cent()
				text-shadow 1px -1px 3px color2
				font-size 20px*/
	flexible-countdown(aspect = imageAspectRatio)
		.item
			width 230px
			height @width * aspect

			fi(s: 0)

			a
				height 100%
			&__image
				width 100%
				height 100%

			@media screen and (max-width: 1180px)
				// когда происходит отключение ограничения контента по ширине; привязать к max-width min:notebook
				width 20vw
				height @width * aspect
			@media tablet
				width 28vw
				height @width * aspect
				&:nth-of-type(4)
					display none
			@media mobile
				width 44vw
				height @width * aspect
				&:nth-of-type(3)
					display none

responsive-carousel(item-width)
	fb(column, space-around, center)
	product(item-width)

	&__cont
		fb(row, center, center)
		margin-bottom (item-width / 4)
		@media media_smallest
			margin-bottom initial
	&__arrow--l
		triangle('left', 2vw, 3vw, rgba(138, 150, 146, 0.7), white)
		margin (item-width / 16)
	&__arrow--r
		triangle('right', 2vw, 3vw, rgba(138, 150, 146, 0.7), white)
		margin (item-width / 16)

	&__main
		fb(row, space-around)
		// ul-reset()
		@media media_smallest
			flex-wrap wrap
freewall-mosaic()
	.mosaic
		margin 0 auto
		max-width mainMaxWidth
		font(cl: color2, fs: 1em, ff: font3, ta: center, tt: uppercase)

		.cell
			margin 0 auto

		&__cell--text > a
			cursor auto
		a
			color color1
			padding 2vw
			center-items(flex)
			height 100%
			width 100%
			cursor crosshair

		for i, category in data.mosaic
			p(category)

			name = category.name
			url1 = category.img1
			url2 = category.img2

			// p(image-size(''+url1))

			if name == "text"
				&__cell--{name}
					pointer-events none //запрет перехода по ссылке(она все равно никуда не ведёт)
					background color2
			else
				width = image-size(url1)[0]
				height = image-size(url1)[1]

				ratio = 1
				// width = unit(round(image-size(url1)[0] / sizeFactor, 2),'vw')
				// height = unit(round(image-size(url1)[1] / sizeFactor, 2),'vw')
				if category.scale
					width = width * category.scale * ratio
					height = height * category.scale * ratio

				&__cell--{name} // интерполяция
					// position(absolute)
					if url1
						width width
						height height

					background url(url1)
					background-size cover
					background-position 50%

					border 1px solid color2
					box-shadow 3px 5px 30px 2px color2
					&:hover
						background url(url2)
						background-size cover
						background-position 50%

					@media small
						background url(url2)
						background-size cover
						background-position 50%
					@media mobile
						width width * 0.6
						height height * 0.6

flex-gallery(n, side-margin = 0, vert-margin = 1em, sel = '.item')
	grid(j: flex-start, w: wrap)
	space-items(side-margin, left, sel: sel, cycle: n)
	// влево, чтобы если всего один элемент, у него не бьло марджина
	& > {sel}
		width unit( ((100 - side-margin*(n - 1)) / n), '%')
		margin-bottom vert-margin
		/*&:not(:nth-child({x}n))
			margin-right side-margin*/
		

	// n - кол-во элементов в ряду
	// margin - правый margin-right
	// применять как-то так:
	// for media, i in [range.mobile, range.tablet, range.notebook, range.desktop]
	// flex-gallery(i+1, margin = 5%)
	// или так
	// for range, i in mobile fablet tablet notebook hd
	// 	@media range
	// 		flex-gallery(i+1, 3%)


bg-slider(slidesCount, resolutions = 1024 1920, bpr = null)
	unless block is defined
		// m = default unless m
		
		if bpr
			//если задан брекпойнт, делаем оверлеем только выше этой точки, ниже - будет в потоке (нужно, если надо надпись и картинку расположить друг над другом)
			+above(bpr)
				overlay()
		else
			overlay()
		
		for i in slidesCount
			.slide-{i}
				size(100%)
				bg(bp: center, bs: cover)
				// adapt-pic(img + 'slide' + i + '.jpg')
				// adapt-pic(img + 'slide' + i + '.jpg', resolutions)
				// придумать, как передавать кастомные аргументы в сабмиксины!
					
				//ещё тут проблема: т.к. задаётся max-width - 1, фактически 1920 действует ВПЛОТЬ до 1919, а на 1920 уже ничего нет
		
	/*.slick-dots
		bottom 20px*/
	
	// default()		
	// 	for i in slidesCount
	// 		.slide-{i}
	// 			adapt-pic(img + 'slide' + i + '.jpg')	

	// {m}() if m

	if block is defined
		{block}


menu-button(w = 3em, ratio = .75, cl = black, hcl = null, x = true)
	
	pos(relative)
	cent()
	flex none
	cursor pointer
	
	width w
	h = @width * ratio
	height h
	
	&__main
		size(100%)
		// b()

		&.is-active-button
			li
				background hcl if hcl
			if x			
				ul
					pos(relative)
					visibility hidden
				/$_x
					content '\002716'
					font-size h*1.3
					overlay()
					cent()
					line-height 0px
				&::before
					@extend $_x
					color cl
				&:hover::before
					@extend $_x
					color hcl
	ul
		size(100%)
		grid(stack)
		&:hover li
			background hcl if hcl
		/*&:hover
			li:nth-of-type(1)
				background #F26437
			li:nth-of-type(2)
				background #8A3442
			li:nth-of-type(3)
				background #35A7A5*/
					


	li
		background cl
		// height 25%
		height 18%
		width 100%

		
scroll-up(type, color = black, w = 60px, bw = 3px)
	// .scroll-up
	backgroundColor = white

	if type is 1
		// display none // изначально скрыт, пока окно не прокручено
		width w
		height @width
		transition opacity 0.1s
		cursor pointer
		&:hover
			opacity 0.8

		&__circle
			background alpha(backgroundColor, .6)
			width 100%
			height @width
			border bw solid color
			border-radius 50%
			cent()

		&__arrow
			pos(relative, 1px, -1px)

			aw = 1.6px
			r = 35deg
			triangle("top", aw*10, aw*10, color)
			transform rotate(-115deg)
			&::after
				content ''
				display block
				triangle('top', aw*3.8, aw*8, color)
				transform rotate(-17.5deg)
				pos(absolute, -(aw*3.7), -(aw*5.7))

	if type is 2
		background alpha(backgroundColor, .6)
		
		width w
		height @width
		transition opacity 0.1s
		cursor pointer
		opacity 0.6
		flex none
		cent()

		&__square
			pos(relative)
			cent()
			width 100%
			height @width

		&:hover
			opacity 1

		&__arrow
			border-left bw solid color
			border-top bw solid color
			width 100%
			height @width
			transform translate(-50%) scale(.6) rotate(45deg) 
			pos(absolute)

		&__arrow:first-of-type
			pos(t: 0%)
		&__arrow:last-of-type
			pos(t: 25%)
		
		.is-top-scroller-inactive &__arrow
			border-left bw solid grey
			border-top bw solid grey	

		&__title
			pos(absolute, b: -35%, l: -.2em)

buyme()
	// сбрасывает стили для input, растягивает и помещает над родителем buyme (который служит для визуального офрмления)
	// размер .buyme задавать каждый раз индивидуально
	pos(relative)
	cent()
	
	input
		overlay()
		padding 0
		border 0 none
		
		background transparent
		cursor pointer

/*buyme(bkgcolor, imgfile = null, txtcolor = false)
	// cent()
	// display inline-flex // т.к. блочный флекс растягивается на всю ширину, если её явно не задать
	display block
	if imgfile
		background url(img + imgfile) no-repeat, bkgcolor
	if txtcolor
		background-size 20%
		background-position 90%
	/*else
		background-size cover*/
	// input
	// 	background transparent
	// 	cursor pointer	
	// 	border 0 none
		// padding .4em 2.6em .4em .8em
		// {block}*/
	
confirm(m = null, b = 0 none, fixHeight = true, extend = null)

	// border для всех детей ставится по дефолту 0 none, если не передан снаружи; 
	// можно не передавать border, а вместо этого можно например передать extend и прописать экстенд-плейсхолдер как более класс с более широким рулсетом

	// INITS
	unless block is defined
		m = default unless m
		
		// +cache('initConst')
		auto-margin()
		// width 100%
		&__captcha-input	
			padding .5em 1em
			
		// +cache('initVars' + border-color)
		& > *
			border b
			
			if extend
				for e in extend
					@extend {e}
			if fixHeight	
				height 45px	// в соответствии с высотой капчи			
		
		if fixHeight
			.button--captcha-confirm
				cent()
					
	// PRESETS
		
	default()
		fb(j: center, w: wrap)
		if !fixHeight
			align-items center
		space-items(1em, left)
			
	vertical()
		space-items(.5em, bottom)
		
		// свойства кнопок, имеющие непосредственное отношения к вёрстке данного блока
		.button--captcha-confirm
		.button--confirm
			width 100%
			margin-left 0 !important
		
	m() if m
	{block}

field(m = null, inputWidth = 0.77, width = 800px, b = 0 none, extend = null)
	//при таких дефолтах и шрифте 16px нормально смотрится вплоть до 800px, дальше ставим вертик.
	// альтернативно можно сделать гибкую общую ширину и фиксировать ширину label в em. а input поставить flex auto чтобы он растягивался

	unless block is defined
		m = row unless m			
		// +cache('inits')

		// b(transparent)
		
		width width
		auto-margin()
		textarea
			height 80px
		input
		textarea
			padding .3em .8em
			border b
			
			if extend
				for e in extend
					@extend {e}

		/*if borderColor
			input
			textarea
				b(borderColor)*/
		
	// PRESETS
		
	row()
		fb(j:space-between)
		input
		textarea
			width (width * inputWidth)

	column()
		width 100%
		flex-direction column
		align-items baseline
		space-items(.5em, top)
		input
		textarea
			width 100%

	m() if m
	{block}

section()
	space-items(1em, bottom)
	// будем использовать для модулей типа заголовок + основной контент, что собственно и является секцией по html5
	// контент под заголовком часто должен быть центрирован
	fb(d: column, a: center)
	// одновременно делает итемы типа инлайн-блоками (ширина определяется по контенту)


/*field(orientation = hor, borderColor = false, inputWidth = 0.85, width = 700px, media = 'screen and (min-width: 800px)')
	//при таких дефолтах и шрифте 16px нормально смотрится вплоть до 800px, дальше ставим вертик.

	width width
	auto-margin()
	textarea
		height 80px
	input
	textarea
		padding .3em .8em

	if borderColor
		input
		textarea
			b(borderColor)

	if orientation == 'hor'
		fb(j:space-between)
		input
		textarea
			width (width * inputWidth)

	vertical()
		width 100%
		flex-direction column
		align-items baseline
		input
		textarea
			width 100%

	if orientation == 'vert'
		vertical()
	if media
		@media media
			vertical()
	{block}*/

/*ks-triangle( direction, size, face-color, back-color = transparent )
	width: 0
	height: 0
	border: size solid back-color
	if direction == up || direction == down-left || direction == down-right
		border-bottom-color: face-color
	if direction == down || direction == up-left || direction == up-right
		border-top-color: face-color
	if direction == left || direction == down-right || direction == up-right
		border-right-color: face-color
	if direction == right || direction == down-left || direction == up-left
		border-left-color: face-color*/

triangle(direction = 'right', width = 100px, height = 100px, color = grey, colorHover = null)
	// width 0

	half-height = height / 2
	half-width = width / 2

	left-right()
		border-bottom solid half-height transparent
		border-top solid half-height transparent

	if direction is 'left'
		left-right()
		border-right solid (half-width) color
		if colorHover
			&:hover
				border-right solid (half-width) colorHover
	if direction is 'right'
		left-right()
		border-left solid (half-width) color
		if colorHover
			&:hover
				border-left solid (half-width) colorHover

	top-bottom()
		border-left solid (half-width) transparent
		border-right solid (half-width) transparent

	if direction is 'top'
		top-bottom()
		border-bottom solid (half-height) color
		if colorHover
			&:hover
				border-right solid (half-height) colorHover
	if direction is 'bottom'
		top-bottom()
		border-top solid (half-height) color
		if colorHover
			&:hover
				border-left solid (half-height) colorHover

arrow(direction = 'right', buttonWidth = 7.5vw, mainColor = white, backColor = color2)
	innerArrowDirection = (direction == 'right') ? 'left' : 'right'
	posLeft = (direction == 'right') ? -(buttonWidth*4/5) : buttonWidth/2
	innerPosLeft = (direction == 'right') ? posLeft + buttonWidth/10 : posLeft

	triangle(direction, buttonWidth, buttonWidth, mainColor)
	opacity 0.4

	&::before
		content ''
		display block
		pos(absolute, t: -(buttonWidth/5), l: posLeft)
		triangle(innerArrowDirection, buttonWidth/5, buttonWidth/5, backColor)

	&::after
		content ''
		display block
		pos(absolute, t: -(buttonWidth/5), l: innerPosLeft + 0.01)
		triangle(innerArrowDirection, buttonWidth/10, buttonWidth/5, mainColor)
		
arrow-slim(cl = color-0, hovercl = lighten(color-0, 40%), thickness = 4px, w = 2.5em, d = 'right')
	width w
	height @width
	cursor pointer
	border-top thickness solid cl
	border-right @border-top
	if hovercl
		&:hover
			border-top thickness solid hovercl
			border-right @border-top
	if d == 'right'
		transform rotate(45deg)

	if d == 'down'
		transform rotate(135deg)

	if d == 'up'
		transform rotate(-45deg)

	if d == 'left'
		transform rotate(-135deg)

sticker(topHeight)
	@media bp-content-a
		grid(stack, g: 1em bottom)
	@media bp-content-b
		grid(g: 1em right, w: nowrap)
		width 100%

	&__top
		flex none
		@media bp-content-a
			height topHeight
		@media bp-content-b
			size(5em)
			
		overflow hidden
		cent()
	&__text
		@media bp-content-a
			text-align center
		@media bp-content-b
			text-align left
		cent()
	
